import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';

import { ContentContext,
         ConfigurationContext } from '../../context';

import { useNavigate } from 'react-router-dom';
   
import { HiMagnifyingGlass } from "react-icons/hi2";

import LoaderComponent from '../../components/elements/LoaderComponent';
import StoresTable from '../../components/Stores/StoresTable';
import ButtonCustom from '../../components/elements/ButtonCustom';
import InputCustom from '../../components/elements/InputCustom';
import SelectCustom from '../../components/elements/SelectCustom';
import { ArrowRightIcon } from '../../helpers/icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { replaceSpecialCharacters,
         isJSON } from '../../helpers/functions';

const WrapperStyled = styled.div`

    width: 100%;
   
    >.actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        
        .bt_create{
            margin-right: 10px;
        }
        .bt_export{
            margin-left: 10px;
        }
        .loader_export{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 42px;
            width: 50px;
            background-color: ${props => `rgb(${props.pancolors.white})`};;
            border: 1px solid ${props => `rgb(${props.pancolors.dots})`};
            border-radius: 4px;
            margin-left: 10px;
            svg{
                color: ${props => `rgb(${props.pancolors.dots})`};
            }
        }
        
    }
    >.filter_actions{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 35px;
        margin-bottom: 20px;
        .field_keywords{
            width: 50vw;
        }
        .filter_option{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            width: 49vw;
            margin-left: 1vw;
            .field_regions{
                width: 8vw;
                margin-left: 1vw;
            }
            .field_status{
                width: 8vw;
                margin-left: 1vw;
            }
            .field_clusters{
                width: 15vw;
                margin-left: 1vw;
            }
            .label{
                font-size: 15px;
                white-space: nowrap;
            }
            .bt_filter{
                margin-left: 1vw;
            }
            .profile{
                margin-left: 10px;
                select{
                    width: 150px;
                }
            }
            .status{
                select{
                    width: 150px;
                }
            }
        }
    }
    >.content{
        //margin-top: 20px;
    }
`

export default function EnhancedTable() {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { basicData,
            exports_bq } = useContext(ContentContext);

    const { panColors,
            clientData, } = useContext(ConfigurationContext);

    const [ filters, setFilters ] = useState(null);
    const [ status, setStatus ] = useState("1");
    const [ regions, setRegions ] = useState("");
    const [ clusters, setClusters ] = useState("");
    const [ loadingReport, setLoadingReport ] = useState(false);
    const [ menuCountryReportEl, setMenuCountryReportEl ] = React.useState(null);

    const statusList = [{_id: "1", name: t("texts.Activos")}, {_id: "-2", name: t("texts.Inactivos")}];

    const [ keywords, setKeywords ] = useState({ value: "", 
                                                 required: false, 
                                                 disabled: false, 
                                                 ref: "keywords", 
                                                 label: t("texts.Palabra / Frase Clave"), 
                                                 placeholder: t("texts.Buscar") + "...",
                                                 help: "",
                                                 error: false})
                                                 
    const regionsRef = React.useRef(regions);
    const clustersRef = React.useRef(clusters);
    const statusRef = React.useRef(status);
    const keywordsRef = React.useRef(keywords);
    const openMenuCountryReport = Boolean(menuCountryReportEl);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    const handleFormElementChange = (name, value) => {

        switch (name) {
            case "status":
                setStatus(value);
                statusRef.current = value;
                break;
            case "regions":
                setRegions(value);
                regionsRef.current = value;
                break;
            case "clusters":
                setClusters(value);
                clustersRef.current = value;
                break;
            case "keywords":
                
                let updateKeywords = {...keywords};
                updateKeywords.value = value;
                setKeywords(updateKeywords);
                keywordsRef.current = value;

                break;
            default:
                break;
        }
    }
    
    const handleClickMenuCountryReport = (event) => {
        setMenuCountryReportEl(event.currentTarget);
    };

    const handleClose = () => {
        setMenuCountryReportEl(null);
    };

    const handleCountryReporting = (code, prefix) => {
        handleClose();
        handleReporting(code, prefix)
    }

    const handleCreate = () => {
        navigate('/stores/create');
    }

    const handleFilter = (e) => {
        const update_filters = {...filters};
        update_filters.status = status;
        update_filters.region = regions;
        update_filters.cluster = clusters;
        update_filters.text_filter = replaceSpecialCharacters(keywords.value);
        setFilters(update_filters);
        localStorage.setItem('stores_filters', JSON.stringify(update_filters));
    }

    const handleReporting = (code, country = '') => {

        setLoadingReport(true);
        const response = exports_bq(code, '', '', country);
        response.then(res => {
            //alert("asd")
            if(res.data.status){
                setLoadingReport(false);
                window.open(res.data.single.location, '_blank').focus();
            }
        }).catch(error => {
            console.log(error);
            setLoadingReport(false);
            alert(t("texts.Hubo un problema al intentar descargar el reporte"));
        })

    }

    const handleKeyDown = (key) => {
        if(key === "Enter"){
            handleFilter();
        }
    }

    const handleCampaigns = () => {
        navigate(`/stores/campaigns`);
    }
    
    useEffect(() => {
        if(basicData){
            
            //localStorage.removeItem('stores_filters');
            if(localStorage.getItem('stores_filters') && isJSON(localStorage.getItem('stores_filters'))){
                const storedFilter = JSON.parse(localStorage.getItem('stores_filters'));

                // console.log("- - - - - - - - - - - - - - - - - - -")
                // console.log(JSON.parse(localStorage.getItem('stores_filters')))
                // console.log("- - - - - - - - - - - - - - - - - - -")
                
                const update_filters = {...filters};
                update_filters.status = storedFilter.status;
                update_filters.region = storedFilter.region;
                update_filters.cluster = storedFilter.cluster;
                update_filters.text_filter = storedFilter.text_filter;
                setFilters(update_filters);

                setRegions(storedFilter.region);
                setStatus(storedFilter.status);
                setClusters(storedFilter.cluster);

                if(storedFilter.text_filter){
                    setKeywords((prevKeywords) => ({ ...prevKeywords, value: storedFilter.text_filter }));
                }
                
            }else{

                const update_filters = {...filters};
                update_filters.status = 1;
                update_filters.region = "";
                update_filters.cluster = "";
                update_filters.text_filter = "";
                setFilters(update_filters);

            }
                
        }
      return () => {}
    }, [basicData])

    if(basicData === null || !filters){
        return null;
    }
    return (
        <WrapperStyled
            pancolors={panColors}
        >
            
            {!basicData ?
                <>cargando</>
            :
                <React.Fragment>

                    <div className='actions'>
                        
                        {((basicData.settings.store.actions.filter(action => action === 'create')).length) ? 
                        <ButtonCustom 
                            className="bt_create"
                            text={`${clientData.name_clients === "one_sp" ? t("texts.Crear Estacion") : t("texts.Crear Tienda") }`}
                            onClick={handleCreate}
                            style_type="normal"

                            icon={null}
                            background={{ normal: panColors.primary, hover: panColors.white }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.white, hover: panColors.primary }}
                        /> : ""}
                        {"campaigns" in basicData && basicData.campaigns.length ?
                        <ButtonCustom 
                            className="bt_go_to_benefits_admin"
                            text={t("texts.Ir a administrar Campanas")}
                            onClick={handleCampaigns}
                            style_type="normal"

                            icon_side={"rigth"}
                            icon={<ArrowRightIcon />}
                            background={{ normal: panColors.white, hover: panColors.primary }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.primary, hover: panColors.white }}
                        /> : ""}

                        {((basicData.settings.store.actions.filter(action => action === 'export')).length) ? 
                        <ButtonCustom 
                            className="bt_export"
                            text={t("texts.Exportar")}
                            onClick={handleCreate}
                            style_type="invert"

                            icon={null}
                            background={{ normal: panColors.white, hover: panColors.primary }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.primary, hover: panColors.white }}
                        /> : ""}

                        

                        {clientData.name_clients === 'one_salesbook' || clientData.name_clients === 'one_gob' || clientData.name_clients === 'one_kc' ?
                            !loadingReport ?
                            <ButtonCustom 
                                className="bt_export"
                                text={`${t("texts.Exportar")} ${loadingReport ? <LoaderComponent /> : ''}`}
                                onClick={(event) => { !loadingReport && handleReporting('pos') }}
                                style_type="invert"

                                icon={null}
                                background={{ normal: panColors.white, hover: panColors.primary }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.primary, hover: panColors.white }}
                                disabled={loadingReport}
                            />
                        :
                            <div className='loader_export'>
                                <LoaderComponent size={20}  />
                            </div> 
                        : ""
                        }


                        {clientData.name_clients === 'one_mars' ?
                            !loadingReport ?
                            <ButtonCustom 
                                className="bt_export"
                                text={`${t("texts.Exportar")} ${loadingReport ? <LoaderComponent /> : ''}`}
                                onClick={(event) => { !loadingReport && handleReporting('stores') }}
                                style_type="invert"

                                icon={null}
                                background={{ normal: panColors.white, hover: panColors.primary }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.primary, hover: panColors.white }}
                                disabled={loadingReport}
                            />
                        :
                            <div className='loader_export'>
                                <LoaderComponent size={20}  />
                            </div> 
                        : ""
                        }

                        {clientData.name_clients === 'one_wts' ?
                            !loadingReport ?
                            <ButtonCustom 
                                className="bt_export"
                                text={`${t("texts.Exportar")} ${loadingReport ? <LoaderComponent /> : ''}`}
                                //onClick={(event) => { !loadingReport && handleReporting('pos') }}
                                onClick={(event) => { !loadingReport && basicData?.countries_reports.length > 1 ? handleClickMenuCountryReport(event) : handleReporting('pos') }}
                                style_type="invert"

                                icon={null}
                                background={{ normal: panColors.white, hover: panColors.primary }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.primary, hover: panColors.white }}
                                disabled={loadingReport}
                            />
                        :
                            <div className='loader_export'>
                                <LoaderComponent size={20}  />
                            </div> 
                        : ""
                        }

                        {clientData.name_clients === 'one_danone' ?
                            !loadingReport ?
                            <ButtonCustom 
                                className="bt_export"
                                text={`${t("texts.Exportar")} ${loadingReport ? <LoaderComponent /> : ''}`}
                                onClick={(event) => { !loadingReport && handleReporting('pos_danone') }}
                                style_type="invert"

                                icon={null}
                                background={{ normal: panColors.white, hover: panColors.primary }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.primary, hover: panColors.white }}
                                disabled={loadingReport}
                            />
                        :
                            <div className='loader_export'>
                                <LoaderComponent size={20}  />
                            </div> 
                        : ""
                        }

                        {basicData?.countries_reports.length ?
                        <Menu
                            id="menu-country-report"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={menuCountryReportEl}
                            open={openMenuCountryReport}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            
                            {basicData?.countries_reports.length ?
                                basicData?.countries_reports.map((country, index) => {
                                    return(
                                        <MenuItem onClick={() => handleCountryReporting('pos', country._id)}>{country.name}</MenuItem>
                                    )
                                })
                            :
                            ""}
                        </Menu> : ""}

                    </div>

                    {basicData.settings.store.section_list_filters.length ?
                    <div className="filter_actions">

                        <InputCustom 
                            name={keywords.ref}
                            label={""}//keywords.label
                            placeholder={keywords?.placeholder}
                            onChange={handleFormElementChange}
                            onKeyDown={handleKeyDown}
                            onKeyUp={null}
                            className={`field field_${keywords.ref}${keywords.error ? " error" : ""}`}
                            type="text"
                            value={keywords.value}
                            help={keywords.help}
                            isRequired={keywords.required}
                            styles={
                                {background_color: `rgba(${panColors.light_white}, 1)`,
                                    border_color: `rgba(${panColors.light_white}, 1)`}
                            }
                            icon={<HiMagnifyingGlass />}
                            tooltip_content={keywords.tooltip}
                            haveError={keywords.error}
                        />

                        <div className="filter_option">
                            <div className="label">
                                {t("texts.Filtrar por")}:
                            </div>
                            <SelectCustom 
                                name={"status"}
                                label={""}//Estado
                                placeholder={null}
                                onChange={handleFormElementChange}
                                className={`field field_status`}
                                type="text"
                                value={status}
                                help={null}
                                isRequired={null}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={false}
                                data={statusList}
                            />
                            <SelectCustom 
                                name={"regions"}
                                label={""}//Estado
                                placeholder={`${t("texts.Region")}...`}
                                onChange={handleFormElementChange}
                                className={`field field_regions`}
                                type="text"
                                value={regions}
                                help={null}
                                isRequired={null}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.regions}
                            />
                            <SelectCustom 
                                name={"clusters"}
                                label={""}
                                placeholder={`${t("texts.Cluster")}...`}
                                onChange={handleFormElementChange}
                                className={`field field_clusters`}
                                type="text"
                                value={clusters}
                                help={null}
                                isRequired={null}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={false}
                                data={basicData?.clusters}
                            />
                            <ButtonCustom 
                                className="bt_filter"
                                text="Filtrar"
                                onClick={handleFilter}
                                style_type="normal"

                                icon={null}
                                background={{ normal: panColors.primary, hover: panColors.white }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.white, hover: panColors.primary }}
                            />
                        </div>

                    </div> : ""}

                </React.Fragment>
            }

            <div className="content">
                <StoresTable filters={filters} /> 
            </div>
        
        </WrapperStyled>
    );
}