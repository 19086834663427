import React, { Fragment, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';

import { ConfigurationContext,
         ContentContext } from '../../context';
         
import { useNavigate, useLocation } from 'react-router-dom';
import { resolveUrl,
         getMenuIcon } from '../../helpers/functions';

const DrawerStyled = styled(Drawer)`

    .MuiDrawer-paper{

        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        z-index: 99999;
        width: ${props => (props.variant === "permanent" && props.status) ? "250px" : props.variant === "temporary" ? "250px" : "90px"};
        background-color: rgb(103, 97, 234);
        box-sizing: border-box;
        padding-left: 20px;
        padding-right: 20px;

        -webkit-transition: width 0.15s 0s linear;
        -moz-transition: width 0.15s 0s linear;
        -o-transition: width 0.15s 0s linear;
        transition: width 0.15s 0s linear;

        -webkit-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.25);
        -moz-box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.25);
        box-shadow: 3px 0px 5px 0px rgba(0,0,0,0.25);

        >.inner{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            ul{
                width: 100%;
                margin: 20px 0 0 0;
                padding: 0;
                li{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    width: ${props => (props.variant === "permanent" && props.status) ? "210px" : props.variant === "temporary" ? "210px" : "50px"};
                    //width: 100%;
                    align-items: center;
                    list-style: none;
                    //margin-bottom: 2px;
                    box-sizing: border-box;
                    padding: 12px 10px 12px 10px;
                    margin-bottom: 10px;
                    border-bottom: 2px solid rgb(103, 97, 234);
                    
                    cursor: pointer;
                    :last-child{
                        margin-bottom: 0;
                    }
                    .icon{
                        display: flex;
                        font-size: 21px;
                        line-height: 21px;
                        width: 30px;
                        justify-content: center;
                        svg{
                            fill: #FFFFFF;
                        }
                    }
                    p{
                        display: ${props => (props.variant === "permanent" && !props.status) ? "none" : "flex"};
                        margin: 0;
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: 700;
                        padding-left: 12px;
                        white-space: nowrap;
                        color: #FFFFFF;
                    }
                    &.selected{
                        border-bottom: 2px solid rgb(36, 34, 49);
                        p{
                            color: rgb(36, 34, 49);
                        }
                        .icon{
                            svg{
                                fill: rgb(36, 34, 49);
                            }
                        }
                    }
                    :hover{
                        border-bottom: 2px solid rgb(36, 34, 49);
                        p{
                            color: rgb(36, 34, 49);
                        }
                        .icon{
                            svg{
                                fill: rgb(36, 34, 49);
                            }
                        }
                    }
                }
            }
            .logo{
                display: flex;
                height: 55px;
                max-width: 200px;
                padding-top: 10px;
                img{
                    float: left;
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
    
`
const DividerH = styled.div`
    display: block;
    width: 90%;
    height: 1px;
    background-color: rgb(200, 200, 200);
    margin-top: 10px;
`

const Sidebar = (props) => {

    const navigate = useNavigate();
    
    const { openDrawer,
            setOpenDrawer,
            menuVariant } = props;
    
    const { pathname } = useLocation();

    const { clientData,
            colors,
            sidebarMenuSelected, 
            setSidebarMenuSelected } = useContext(ConfigurationContext);
            
    const { basicData } = useContext(ContentContext);

    useEffect(() => {

        const split_path = pathname.split("/");
        setSidebarMenuSelected(split_path[1]);
        
      return () => {}
    }, [pathname])
    
    const handleGoto = (el) => {
        if(el !== "999"){
            if(el !== "dashboard"){
                setSidebarMenuSelected(resolveUrl(el));
                navigate(`/${resolveUrl(el)}`)
            }
            if(el === "dashboard"){
                setSidebarMenuSelected("dashboard");
                navigate(`/dashboard`)
            }
        }else{
            window.open(`https://v2-importers.1onesolutions.com/?token=${localStorage.getItem('access_token')}`, '_blank');
        }
        setOpenDrawer(false)
    }
    
    
  return (
    <Fragment>
        <DrawerStyled
            colors={colors}
            anchor={'left'}
            elevation={3}
            status={openDrawer}
            open={openDrawer}
            onClose={() => setOpenDrawer(!openDrawer)}
            variant={menuVariant}
        >   
            <div className="inner">
                <div className="logo">
                    <img src={clientData ? clientData.logo_client : ""} />
                </div>
                <DividerH />
                <ul>
                
                {pathname !== "/country" && basicData && basicData.settings.options &&
                    basicData.settings.options.map((el, i) => {
                        //console.log("el", basicData.user_id)
                        if(basicData.user_id === "670ff97fd1fc4a1ea1b3a67f" && el.id === "8"){ return null; }
                        return(
                            <li key={"menu_el" + i}
                                className={`${resolveUrl(el.id) === sidebarMenuSelected ? "selected" : ""}`}
                                onClick={() => handleGoto(el.id)}
                            >
                                <div className="icon">
                                    {getMenuIcon(el)}
                                </div>
                                <p>{el.label}</p>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </DrawerStyled>
    </Fragment>
  )
  
}

export default Sidebar;
