import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';
import InputCustom from './InputCustom';
import ButtonCustom from './ButtonCustom';
import { validateParamName } from '../../helpers/functions';
import { useTranslation } from 'react-i18next';
import { DocumentScannerIcon, TrashIcon, InfoIcon } from '../../helpers/icons';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-end;
    width: 100%;
    
    background-color: ${props => `rgb(${props.panColors.light_white})`};
    box-sizing: border-box;

    .fields{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% - 60px);
        padding: 25px 30px 25px 30px;
        .info_title,
        .info_text{
            width: calc(50% - 10px);
        }
    }
    .actions{
        box-sizing: border-box;
        min-height: 100%;
        padding: 15px 15px 15px 0;
        .bt_submit{
            min-height: 100%;
            box-sizing: border-box;
            padding: 0 15px 0 15px;
            .icon{
                svg{
                    width: 23px;
                    height: 23px;
                }
            }
        }
    }
`

const AditionalInformationElement = (props) => {

    const { t } = useTranslation();

    const { panColors } = useContext(ConfigurationContext);

    const { className,
            element,
            aditionalInfo,
            setAditionalInfo } = props;

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    const [ labelError, setLabelError ] = useState(false);

    const handleFormElementChange = (name, value) => {
        
        let labelStatusError = false;
        switch (name) {
            case "label":
                labelStatusError = validateParamName(value);
                setLabelError(!labelStatusError);
                break;
        
            default:
                break;
        }

        const updated = aditionalInfo.map((el, i) => {
            if(el.id === element.id){
                
                el[name] = value;

                if(name === "label"){
                    el["label_error"] = !labelStatusError;
                }
                
            }
            return el;
        })

        setAditionalInfo(updated);

    }

    const handleRemoveElement = (id) => {
        const elements = aditionalInfo.filter(el => el.id !== id);
        setAditionalInfo(elements);
    }
    
    return (
        <Wrapper
            className={className}
            panColors={panColors}
        >
            <div className="fields">
                <InputCustom 
                    name="label"
                    label={t("texts.Titulo de la informacion adicional")}
                    placeholder={`${t("texts.Agregar titulo para nueva informacion")}...`}
                    onChange={handleFormElementChange}
                    className={`field info_title${element.label_error ? " error" : ""}`}
                    type="text"
                    value={element && element.label}
                    help={t("texts.Letras, numeros y _")}
                    isRequired={true}
                    styles={ formStyle }
                    label_icon={<InfoIcon />}
                    tooltip_content={<div className="tooltip_content">Texto de ayuda</div>}
                    haveError={element.label_error}
                />

                <InputCustom 
                    name="value"
                    label="Información"
                    placeholder={`${t("texts.Completar informacion")}...`}
                    onChange={handleFormElementChange}
                    className={`field info_text${element.value_error ? " error" : ""}`}
                    type="text"
                    value={element && element.value}
                    help=""
                    isRequired={true}
                    styles={ formStyle }
                    label_icon={<DocumentScannerIcon />}
                    tooltip_content={""}
                    haveError={element.value_error}
                />
            </div>
            
            <div className='actions'>
                <ButtonCustom 
                    className="bt_submit"
                    text=""
                    onClick={() => handleRemoveElement(element.id)}
                    style_type="normal"
                    
                    icon={<TrashIcon />}
                    background={{ normal: panColors.white, hover: panColors.white }}
                    border={{ normal: panColors.red, hover: panColors.primary }}
                    textcolor={{ normal: panColors.red, hover: panColors.primary }}
                />
            </div>

        </Wrapper>

    )

}

export default AditionalInformationElement;