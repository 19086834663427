import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider,
         ConfigurationProvider } from './context';
import { CircularProgress } from '@mui/material';

import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <BrowserRouter>
      <ConfigurationProvider>
        <AuthProvider>
          <Suspense fallback={<CircularProgress size={50} />}>
            <App />
          </Suspense>
          
        </AuthProvider>
      </ConfigurationProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
