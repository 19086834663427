import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import Form from '../../../components/Stores/Form';
import SuccessForm from '../../../components/elements/SuccessForm';
import successImage from '../../../assets/images/img_success_stores.svg';
import { useTranslation } from 'react-i18next';
import { ConfigurationContext } from '../../../context';

const WrapperStyled = styled.div`
    width: 100%;
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .bt_submit{

        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
`



const CreateContainer = (props) => {

    const { t } = useTranslation();

    const { panColors } = useContext(ConfigurationContext);
                       
    const [ success, setSuccess ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);

    return (
        <WrapperStyled pancolors={panColors}>
            
            {!success ? 
                <Form 
                    data={null}
                    action="create"
                    loadingForm={loadingForm}
                    setLoadingForm={setLoadingForm}
                    success={success}
                    setSuccess={setSuccess}
                />
            :
                <SuccessForm 
                    image={successImage} 
                    actionSuccessText={t("texts.Tienda agregada con exito")}
                    resultSuccessText={t("texts.Ahora se puede encontrar esta tienda activa en la lista de tiendas")}
                    buttonBackToListText={t("texts.Volver a listado de tiendas")}
                    buttonCreateNewText={t("texts.Crear tienda nueva")}
                    buttonBackToListStyle={"normal"}
                    buttonCreateNewStyle={"invert"}
                    createNew={"/stores/create"}
                    backTo={"/stores"}
                    setSuccess={setSuccess}
                />
            }
            

        </WrapperStyled>
    );
}

export default CreateContainer;