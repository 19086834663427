import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import Form from '../../../components/Evaluations/Form';
import EvaluationContainer from '../../../components/Evaluations/EvaluationContainer';
import FormTopMessage from '../../../components/elements/FormTopMessage';
import FormBottomMessage from '../../../components/elements/FormBottomMessage';
import SuccessForm from '../../../components/elements/SuccessForm';
import successImage from '../../../assets/images/img_success_assessment.svg';

import { dataObjectEvaluations,
         getTypeAssesment } from '../../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { useNavigate, useParams } from 'react-router-dom';

import ButtonCustom from '../../../components/elements/ButtonCustom';
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import LoaderComponent from '../../../components/elements/LoaderComponent';
import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`
    width: 100%;
    .box_evaluation_inner{
        display: flex;
        flex-direction: column;
        >.head_form{
            display: flex;
            flex-direction: column;
            color: ${props => `rgb(${props.pancolors.black})`};
            margin-bottom: 20px;
            margin-top: 15px;
            >.title{
                font-size: 22px;
                line-height: 26px;
                font-weight: 600;
            }
            >.name{
                font-size: 16px;
                line-height: 22px;
                margin-top: 5px;
                >.strong{
                    font-weight: 600;
                }
            }
        }
    }
    .loader{
        margin-top: 20px;
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
`

const CreateContainer = (props) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { id_course, id_module } = useParams();

    const { getCourse,
            setCourse,
            course,
            getModule,
            module,
            setModule,
            basicData,
            idCourseSelected } = useContext(ContentContext);
            
    const { panColors,
            userCountrySelected } = useContext(ConfigurationContext);

    const { setEvaluation,
            setEvaluationShow } = props;
                       
    const [ success, setSuccess ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);
    const [ contentByLang, setContentByLang ] = useState({});
    const [ haveErrorForm, setHaveErrorForm ] = useState(false);
    const [ errorFormMessage, setErrorFormMessage ] = useState({top: t("texts.Faltan los datos remarcados"), bottom: t("texts.Corrige informacion faltante o erronea marcada")});
    const [ initLoadEvaluation, setInitLoadEvaluation ] = useState(false);
    const [ idCourse, setIdCourse ] = useState(null);
    const [ idModule, setIdModule ] = useState(null);
    const [ idAssessment, setIdAssessment ] = useState(null);
    const [ titleSection, setTitleSection ] = useState({section: "", title: ""});
    
    const [ assessmentType, setAssessmentType ] = useState(null);
    const [ selectedLang, setSelectedLang ] = useState(null);

    useEffect(() => {
        
        if(basicData && userCountrySelected){
            
            handleInitForm();
            
        }

        return () => {}
    }, [basicData, userCountrySelected])

    useEffect(() => {

        if(course && basicData && userCountrySelected){
            setIdCourse(course._id);
            setIdModule(null);
            setTitleSection({section: t("texts.Curso")+":", title: course?.translations[userCountrySelected.languages[0].language_identifier]?.title});
        }
        
        if(module && basicData && userCountrySelected){
            setIdModule(module._id);
            setIdCourse(null);
            setTitleSection({section: t("texts.Contenido")+":", title: module?.translations[userCountrySelected.languages[0].language_identifier]?.title});
        }

        return () => {}
    }, [course, module, basicData, userCountrySelected]);

    useEffect(() => {
        
        if(id_module){
            getModule(id_module);
        }

        if(id_course){
            getCourse(id_course);
        }

        return () => {}
    }, [id_course, id_module])

    const handleInitForm = () => {
        let contentByLangUpdate = {...contentByLang};
        contentByLangUpdate =  dataObjectEvaluations(null, "create");
        contentByLangUpdate.translations = {};

        userCountrySelected.languages.map((lang, index) => {
            contentByLangUpdate.translations[lang.language_identifier] = {};
            contentByLangUpdate.translations[lang.language_identifier].questions = [];
        })
        setContentByLang(contentByLangUpdate);
    }

    const handleStartLoadEvaluation = () => {
        
        let haveError = false;
        let updateData = {...contentByLang};

        Object.keys(updateData).map(function(key) {
            if(updateData[key].required){
                if(!updateData[key].value.length){
                    updateData[key].error = true;
                    haveError = true;
                }else{
                    updateData[key].error = false;
                }
            }
            
        })
        
        setContentByLang(updateData);
        
        if(!haveError){

            const _type_assessment = getTypeAssesment(basicData.assessment_type, updateData.type_assessment.value);
            setAssessmentType(_type_assessment);
            setInitLoadEvaluation(true);

        }

    }

    const handleCancel = () => {
        if(id_course){
            navigate(`/courses`);
        }
        if(id_module){
            navigate(`/modules`);
        }
    }
    
    useEffect(() => {
        if(userCountrySelected){
            if(userCountrySelected.languages.length){
                setSelectedLang(userCountrySelected.languages[0].language_identifier);
            }
        }
    return () => {}
    }, [userCountrySelected])

    useEffect(() => {
      return () => {
        setIdCourse(null);
        setIdModule(null);
        setTitleSection({section: "", title: ""});
        setContentByLang({});
        setModule(null);
        setCourse(null);
      }
    }, [])

    return (
        <WrapperStyled pancolors={panColors}>
            
            {!success ? 
                
                <>
                {selectedLang ?
                    <>
                        <FormTopMessage 
                            haveError={haveErrorForm}
                            text={errorFormMessage.top}
                        />

                        <div className="box_evaluation_inner">
                            <div className='head_form'>
                                <div className="title">{t("texts.Evaluacion")}</div>
                                <div className="name">{titleSection.section} <span className="strong">{titleSection.title}</span></div>
                            </div>
                        </div>

                        {!initLoadEvaluation ? 
                            <div className="box_init_form">
                                <Form 
                                    lang={selectedLang}
                                    data={contentByLang}
                                    scope="create"
                                    loadingForm={loadingForm}
                                    setLoadingForm={setLoadingForm}
                                    success={success}
                                    contentByLang={contentByLang}
                                    setContentByLang={setContentByLang}
                                    assessmentType={assessmentType}
                                    setAssessmentType={setAssessmentType}
                                />

                                <div className="actions">
                                    <ButtonCustom 
                                        className="bt_submit"
                                        text={t("texts.Comenzar a cargar evaluacion")}
                                        onClick={handleStartLoadEvaluation}
                                        style_type="normal"
                                        
                                        icon={null}
                                        background={{ normal: panColors.primary, hover: panColors.white }}
                                        border={{ normal: panColors.primary, hover: panColors.primary }}
                                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                                    />
                                    <ButtonTextCustom 
                                        className="bt_cancel"
                                        text={t("texts.Cancelar")}
                                        onClick={handleCancel}

                                        icon={null}
                                        background={{ normal: "", hover: "" }}
                                        border={{ normal: "", hover: "" }}
                                        textcolor={{ normal: panColors.red, hover: panColors.red }}
                                    />
                                </div>
                            </div>
                            : ""
                        }

                        {initLoadEvaluation ? 
                            <div className="box_init_form">
                                <EvaluationContainer 
                                    lang={selectedLang}
                                    data={contentByLang}
                                    scope="create"
                                    loadingForm={loadingForm}
                                    setLoadingForm={setLoadingForm}
                                    success={success}
                                    setSuccess={setSuccess}
                                    contentByLang={contentByLang}
                                    setContentByLang={setContentByLang}
                                    setEvaluation={setEvaluation}
                                    setEvaluationShow={setEvaluationShow}
                                    idCourse={idCourse}
                                    idModule={idModule}
                                    idAssessment={idAssessment}
                                    setIdAssessment={setIdAssessment}
                                    assessmentType={assessmentType}
                                />
                            </div>
                            : ""
                        }
                        
                        <FormBottomMessage 
                            className="form_error_bottom"
                            haveError={haveErrorForm}
                            text={errorFormMessage.bottom}
                        />
                    </>
                    :
                    <div className='loader'>
                        <LoaderComponent />
                    </div>    
                    }
                </>
                :
                <SuccessForm 
                    image={successImage} 
                    actionSuccessText={t("texts.Se ha publicado su evaluacion con exito")}
                    resultSuccessText={t("texts.Ahora se puede encontrar esta evaluacion activa")}
                    buttonBackToListText={t("texts.Volver al listado de", {to: id_module ? t("texts.Contenidos") : id_course ? t("texts.Cursos") : ""})}
                    buttonCreateNewText={null}
                    buttonCreateSegmentationText={null}
                    buttonBackToListStyle={`normal`}
                    buttonCreateNewStyle={`invert`}
                    createNew={""}
                    createSegmentation={null}
                    //backTo={`${id_course ? `/course/${id_course}/evaluation/${idAssessment}/view` : id_module ? `/module/${id_module}/evaluation/${idAssessment}/view` : ""}`}
                    backTo={`${idCourse ? `/courses` : ""}${idModule ? `/course/${idCourseSelected}/modules` : ""}`}
                    setSuccess={setSuccess}
                />
            }
        </WrapperStyled>
    );
}
export default CreateContainer;