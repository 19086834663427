import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';
import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`
    .top_messages{
        margin-bottom: 10px;
        .preform_message{
            font-weight: 700;
            font-size: 14px;
            color: ${props => `rgb(${props.panColors.dark_gray})`};
            margin-bottom: 5px;
        }
        .error_message{
            font-weight: 700;
            font-size: 14px;
            color: ${props => `rgb(${props.panColors.red})`};
        }
        &.error{
            .preform_message{
                font-weight: 600;
                color: ${props => `rgb(${props.panColors.red})`};
            }
            .error_message{
                font-weight: 700;
                font-size: 14px;
            }
        }
    }
`

const FormTopMessage = (props) => {

    const { t } = useTranslation();

    const { panColors } = useContext(ConfigurationContext);

    const { text,
            haveError,
            className } = props;
    
    
    return (
        <WrapperStyled 
            panColors={panColors}
            className={className}
        >
            <div className={`top_messages ${haveError ? " error" : ""}`}>
                <div className="preform_message">
                    { t("texts.La informacion obligatoria marcada con") }
                </div>
                {haveError &&
                    <div className="error_message">
                        {text}
                    </div>
                }
            </div>

        </WrapperStyled>

    )

}

export default FormTopMessage;