import React, { useState, useContext, useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { ContentContext,
         ConfigurationContext } from '../../context';

import { UserIcon, 
         RetailersIcon1,
         CountryIcon,
         LocalizationAreaIcon,
         LongitudeIcon,
         LatitudeIcon,
         ClockCheckoutIcon,
         RegionIcon,
         ClusterIcon,
         FormatIcon } from '../../helpers/icons';

import { dataObjectStore,
         validateLatLng,
         getCitiesByCountry,
         validateOnlyIntegers } from '../../helpers/functions';
         
import ModalImageProfile from '../../components/elements/Modals/ModalImageProfile';
import InputCustom from '../../components/elements/InputCustom';
import SelectCustom from '../../components/elements/SelectCustom';
import ButtonCustom from '../../components/elements/ButtonCustom';
import ButtonTextCustom from '../../components/elements/ButtonTextCustom';
import LoaderComponent from '../../components/elements/LoaderComponent';
import AditionalInformationElement from '../../components/elements/AditionalInformationElement';
import FormTopMessage from '../../components/elements/FormTopMessage';
import FormBottomMessage from '../../components/elements/FormBottomMessage';
import ScheduleCreate from '../../components/elements/Dialogs/ScheduleCreate';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;

    .box_form{
        display: grid; 
        grid-template-columns: repeat(3, 1fr);
        gap: 50px;
        .box_column{

        }
        .date_picker{
            width: 100%;
            input{
                padding: 9.5px 14px!important;
                color: #484D63;
                font-size: 16px;
            }
            
            fieldset{
                border-color: rgba(186,186,194,1)!important;
                border-width: 1px;
            }
        }
    }
    .store_image{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100px;
        margin-bottom: 25px;
        .store_cover{
            svg{
                width: 50px!important;
            }
        }
        .add_store_image{
            display: flex;
            flex-direction: column;
            margin-left: 20px;
            align-items: flex-start;
            .bt_add_store_image{
                height: auto;
                padding: 0;
                >.text{
                    margin-left: 0;
                }
            }
            .bt_delete_store_image{
                height: auto;
                padding: 0;
                margin-top: 10px;
            }
            .aclaration_bt_add_store_image{
                font-size: 12px;
                line-height: 14px;
                font-weight: 700;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                margin-top: 10px;
            }
        }
    }
    .box_fields{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        >.title{
            color: ${props => `rgb(${props.pancolors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        >.field{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 25px;
            :last-child{
                margin-bottom: 0;
            }
        }
        
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .bt_submit{

        }
        .bt_program_activation{
            margin-left: 10px;
        }
        .bt_cancel{
            margin-left: 10px;
        }
        >.loader{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            margin: 0;
            border-radius: 4px;
            background-color: ${props => `rgb(${props.pancolors.primary})`};
        }
    }
    .add_aditional_information{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 30px;
        .bt_add_more_info{
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            padding: 0;
            margin-bottom: 30px;
        }
        .aditional_information_el{
            margin-bottom: 10px;
        }
    }
`

const Form = (props) => {

    const { t } = useTranslation();

    const { data = null,
            action,
            loadingForm,
            setLoadingForm,
            success,
            setSuccess } = props;
    
    const { basicData,
            createStore,
            editStore } = useContext(ContentContext);

    const { panColors,
            clientData } = useContext(ConfigurationContext);

    const navigate = useNavigate();
    const { id_store } = useParams();

    let inputRef = useRef({});
    
    const [ aditionalInfo, setAditionalInfo ] = useState([]);
    const [ formData, setFormData ] = useState({});
    const [ validateStatus, setValidateStatus ] = useState(null);
    const [ scheduleCreateOpen, setScheduleCreateOpen ] = useState(false);
    const [ openModalImageProfile, setOpenModalImageProfile ] = useState(false);
    const [ croppedImage, setCroppedImage ] = useState(null);
    const [ uploadRoute, setUploadRoute ] = useState(null);
    const [ profileImage, setProfileImage ] = useState(null);
    const [ cities, setCities ] = useState(null);
    const [ haveErrorForm, setHaveErrorForm ] = useState(false);
    const [ errorFormMessage, setErrorFormMessage ] = useState({top: t("texts.Faltan los datos remarcados"), bottom: t("texts.Corrige informacion faltante o erronea marcada")});
    
    
    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    useEffect(() => {
        if(basicData){
            setFormData(dataObjectStore(data, basicData.settings.store.section_fields));
        }
        if(data){
            setAditionalInfo(data.additional);
        }

        return () => {
            setFormData({});
        }

    }, [data, basicData])

    useEffect(() => {
        
        if(formData){
            if('avatar' in formData && formData.avatar.value != ""){
                setProfileImage(formData.avatar.value);
            }
        }
     
        return () => {}

    }, [formData?.avatar])

    useEffect(() => {

        if(Object.keys(formData).length && basicData){
            if(formData.country.value !== ""){
                const cities_response = getCitiesByCountry(basicData.countries, formData.country.value);
                setCities(cities_response);
            }
        }
     
        return () => {}

    }, [formData, basicData])

    

    useEffect(() => {
      
        setProfileImage(croppedImage);
    
      return () => {}
    }, [croppedImage])
    
    useEffect(() => {
      
        if(success == true && action === "create"){
            resetForm();
        };
    
      return () => {}
    }, [success])

    
    const handleFormElementChange = (name, value) => {

        let updateFormData = {...formData};
        let isValid;

        switch (name) {
            case "maximum_distance_for_checking":
                if(value.length){
                    isValid = validateOnlyIntegers(value);
                    if(!isValid){
                        updateFormData[name].error = true;
                    }else{
                        updateFormData[name].error = false;
                    }
                }else{
                    updateFormData[name].error = false;
                }
                updateFormData[name].value = value;
                break;
            case "lat":
            case "lng":
                if(value.length){
                    isValid = validateLatLng(value); 
                    if(!isValid){
                        updateFormData[name].error = true;
                    }else{
                        updateFormData[name].error = false;
                    }
                }else{
                    updateFormData[name].error = false;
                }
                updateFormData[name].value = value;
                break;
                
            default:
                updateFormData[name].value = value;
                break;
        }

        
        setFormData(updateFormData);

       

        return null;
    }

    const handleSubmit = () => {
        let haveError = false;
        if(Object.keys(formData).length){
            
            let updateFormData = {...formData};
            
            Object.keys(formData).map(function(key) {
                //
                if(formData[key].required && formData[key].visibility){
                    if(!formData[key].value.toString().length){
                        
                        haveError = true;
                        updateFormData[key].error = true;
                        
                    }else{
                        updateFormData[key].error = false;
                    }

                    
                
                }
                
                
            })
            
            setErrorFormMessage({top: t("texts.Faltan los datos remarcados"), bottom: t("texts.Corrige informacion faltante o erronea marcada")})
            setHaveErrorForm(haveError);

            if(!haveError){

                handleSubmitForm(updateFormData, aditionalInfo);
                setFormData(updateFormData);
                
            }

        }

    }

    const handleSubmitForm = (_payload, aditionalInfo) => {
        
        setLoadingForm(true);

        let aditionalInfoFinal = [];

        if(aditionalInfo.length > 0 ) {
            aditionalInfoFinal = aditionalInfo.map(info => {
                return {label: info.label, value: info.value};
            })
        }
        
        let request;
        let payload = {};

        formData.country.visibility && (payload.country = _payload.country.value);
        formData.name.visibility && (payload.name = _payload.name.value);
        formData.code.visibility && (payload.code = _payload.code.value);
        formData.maximum_distance_for_checking.visibility && (payload.maximum_distance_for_checking = _payload.maximum_distance_for_checking.value);
        formData.address.visibility && (payload.address = _payload.address.value);
        formData.lat.visibility && (payload.lat = _payload.lat.value);
        formData.lng.visibility && (payload.lng = _payload.lng.value);
        formData.retailer.visibility && (payload.retailer = _payload.retailer.value);
        formData.area.visibility && (payload.area = _payload.area.value);
        formData.region.visibility && (payload.region = _payload.region.value);
        formData.flag.visibility && (payload.flag = _payload.flag.value);
        formData.format.visibility && (payload.format = _payload.format.value);
        formData.cluster.visibility && (payload.cluster = _payload.cluster.value);
        basicData.settings.store.section_fields.additional.visibility && (payload.additional = JSON.stringify(aditionalInfoFinal));
        
        if(action === "create"){
            request = createStore(payload);
        }
        if(action === "edit"){
            payload.store_id = id_store;
            request = editStore(payload);
        }
            
        request.then(res => {
                setLoadingForm(false);
                setSuccess(true);
            }).catch(err => {
                setErrorFormMessage({top: err.message, bottom: err.message})
                setHaveErrorForm(true);
                setLoadingForm(false);
            })
        
    }
    
    const handleCancel = () => {
        navigate("/users");
    }
    
    const handleAddAditionalInformation = () => {
        const _uuid = uuid();
        setAditionalInfo(aditionalInfo => [{id: _uuid, label: "", value: ""}, ...aditionalInfo]);
    }
    
    const resetForm = () => {
        
        Object.keys(inputRef.current).map(function(key) {
            inputRef.current[key].value = '';
        })
        Object.keys(formData).map(function(key) {
            formData[key].value = '';
        })
        setAditionalInfo([]);
        setOpenModalImageProfile(false);
        setCroppedImage(null);
        setUploadRoute(null);
        setProfileImage(null);
        setValidateStatus(null);

    }

    return (
        <>

            {openModalImageProfile &&
                <ModalImageProfile 
                    openModalImageProfile={openModalImageProfile} 
                    setOpenModalImageProfile={setOpenModalImageProfile} 
                    croppedImage={croppedImage}
                    setCroppedImage={setCroppedImage}
                    uploadRoute={uploadRoute}
                    setUploadRoute={setUploadRoute}
                />
            }
            <ScheduleCreate open={scheduleCreateOpen} setOpen={setScheduleCreateOpen} />
            
            {!Object.keys(formData).length || !Object.keys(basicData).length
            ?
            <>carga</>
            :
            <WrapperStyled
                pancolors={panColors}
            >

                <FormTopMessage 
                    haveError={haveErrorForm}
                    text={errorFormMessage.top}
                />

                <div className="box_form">
                    <div className="box_column">
                        <div className="box_fields">

                            <div className="title">
                                {clientData.name_clients !== "one_sp" ? t("texts.Datos de la tienda") : t("texts.Datos de la estacion")}
                            </div>

                            {basicData.settings.store.section_fields.country.visibility ?
                            <SelectCustom 
                                name={formData?.country?.ref}
                                label={formData?.country?.label}
                                className={`field field_${formData?.country?.ref}${formData?.country?.error ? " error" : ""}`}
                                value={formData?.country?.value}
                                isRequired={formData?.country?.required}
                                placeholder={formData?.country?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.country?.help}
                                styles={ formStyle }
                                label_icon={<CountryIcon />}
                                tooltip_content={formData?.country?.tooltip}
                                haveError={formData?.country?.error}
                                data={basicData?.countries}
                            /> : ""}

                            {basicData.settings.store.section_fields.name_store.visibility ?
                            <InputCustom 
                                name={formData?.name?.ref}
                                label={formData?.name?.label}
                                placeholder={formData?.name?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${formData?.name?.ref}${formData?.name?.error ? " error" : ""}`}
                                type="text"
                                value={formData?.name?.value}
                                help={formData?.name?.help}
                                isRequired={formData?.name?.required}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                tooltip_content={formData?.name?.tooltip}
                                haveError={formData?.name?.error}
                            /> : ""}

                            {basicData.settings.store.section_fields.code.visibility ?
                            <InputCustom 
                                name={formData?.code?.ref}
                                label={formData?.code?.label}
                                placeholder={formData?.code?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${formData?.code?.ref}${formData?.code?.error ? " error" : ""}`}
                                type="text"
                                value={formData?.code?.value}
                                help={formData?.code?.help}
                                isRequired={formData?.code?.required}
                                styles={ formStyle }
                                label_icon={<UserIcon />}
                                tooltip_content={formData?.code?.tooltip}
                                haveError={formData?.code?.error}
                            /> : ""}

                            {basicData.settings.store.section_fields.maximum_distance_for_checking.visibility ?
                            <InputCustom 
                                name={formData?.maximum_distance_for_checking?.ref}
                                label={formData?.maximum_distance_for_checking?.label}
                                placeholder={formData?.maximum_distance_for_checking?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                className={`field field_${formData?.maximum_distance_for_checking?.ref}${formData?.maximum_distance_for_checking?.error ? " error" : ""}`}
                                type="text"
                                value={formData?.maximum_distance_for_checking?.value}
                                help={formData?.maximum_distance_for_checking?.help}
                                isRequired={formData?.maximum_distance_for_checking?.required}
                                styles={ formStyle }
                                label_icon={<ClockCheckoutIcon />}
                                tooltip_content={formData?.maximum_distance_for_checking?.tooltip}
                                haveError={formData?.maximum_distance_for_checking?.error}
                            /> : ""}
                        </div>
                    </div>

                    <div className="box_column">
                        <div className="box_fields">
                            
                            {basicData.settings.store.section_fields.address.visibility ?
                            <InputCustom 
                                name={formData?.address?.ref}
                                label={formData?.address?.label}
                                className={`field field_${formData?.address?.ref}${formData?.address?.error ? " error" : ""}`}
                                value={formData?.address?.value}
                                isRequired={formData?.address?.required}
                                placeholder={formData?.address?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                type="text"
                                help={formData?.address?.help}
                                styles={ formStyle }
                                label_icon={<LatitudeIcon />}
                                tooltip_content={formData?.address?.tooltip}
                                haveError={formData?.address?.error}
                            /> : ""}

                            {basicData.settings.store.section_fields.lat.visibility ?
                            <InputCustom 
                                name={formData?.lat?.ref}
                                label={formData?.lat?.label}
                                className={`field field_${formData?.lat?.ref}${formData?.lat?.error ? " error" : ""}`}
                                value={formData?.lat?.value}
                                isRequired={formData?.lat?.required}
                                placeholder={formData?.lat?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                type="text"
                                help={formData?.lat?.help}
                                styles={ formStyle }
                                label_icon={<LongitudeIcon />}
                                tooltip_content={formData?.lat?.tooltip}
                                haveError={formData?.lat?.error}
                            /> : ""}

                            {basicData.settings.store.section_fields.lng.visibility ?
                            <InputCustom 
                                name={formData?.lng?.ref}
                                label={formData?.lng?.label}
                                className={`field field_${formData?.lng?.ref}${formData?.lng?.error ? " error" : ""}`}
                                value={formData?.lng?.value}
                                isRequired={formData?.lng?.required}
                                placeholder={formData?.lng?.placeholder}
                                onChange={handleFormElementChange}
                                onKeyDown={null}
                                type="text"
                                help={formData?.lng?.help}
                                styles={ formStyle }
                                label_icon={<LongitudeIcon />}
                                tooltip_content={formData?.lng?.tooltip}
                                haveError={formData?.lng?.error}
                            /> : ""}

                            {basicData.settings.store.section_fields.retailer.visibility ?
                            <SelectCustom 
                                name={formData?.retailer?.ref}
                                label={formData?.retailer?.label}
                                className={`field field_${formData?.retailer?.ref}${formData?.retailer?.error ? " error" : ""}`}
                                value={formData?.retailer?.value}
                                isRequired={formData?.retailer?.required}
                                placeholder={formData?.retailer?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.retailer?.help}
                                styles={ formStyle }
                                label_icon={<RetailersIcon1 />}
                                tooltip_content={formData?.retailer?.tooltip}
                                haveError={formData?.retailer?.error}
                                data={basicData?.retailers}
                            /> : ""}
                            
                        </div>
                        
                    </div>

                    <div className="box_column">
                        <div className="box_fields">
                            {basicData.settings.store.section_fields.area.visibility ?
                            <SelectCustom 
                                name={formData?.area?.ref}
                                label={formData?.area?.label}
                                className={`field field_${formData?.area?.ref}${formData?.area?.error ? " error" : ""}`}
                                value={formData?.area?.value}
                                isRequired={formData?.area?.required}
                                placeholder={formData?.area?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.area?.help}
                                styles={ formStyle }
                                label_icon={<LocalizationAreaIcon />}
                                tooltip_content={formData?.area?.tooltip}
                                haveError={formData?.area?.error}
                                data={basicData?.areas}
                            /> : ""}

                            {basicData.settings.store.section_fields.region.visibility ?
                            <SelectCustom 
                                name={formData?.region?.ref}
                                label={formData?.region?.label}
                                className={`field field_${formData?.region?.ref}${formData?.region?.error ? " error" : ""}`}
                                value={formData?.region?.value}
                                isRequired={formData?.region?.required}
                                placeholder={formData?.region?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.region?.help}
                                styles={ formStyle }
                                label_icon={<RegionIcon />}
                                tooltip_content={formData?.region?.tooltip}
                                haveError={formData?.region?.error}
                                data={basicData?.regions}
                            /> : ""}

                            {basicData.settings.store.section_fields.flag.visibility ?
                            <SelectCustom 
                                name={formData?.flag?.ref}
                                label={formData?.flag?.label}
                                className={`field field_${formData?.flag?.ref}${formData?.flag?.error ? " error" : ""}`}
                                value={formData?.flag?.value}
                                isRequired={formData?.flag?.required}
                                placeholder={formData?.flag?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.flag?.help}
                                styles={ formStyle }
                                label_icon={<RegionIcon />}
                                tooltip_content={formData?.flag?.tooltip}
                                haveError={formData?.flag?.error}
                                data={basicData?.flags}
                            /> : ""}
                            
                            {basicData.settings.store.section_fields.format.visibility ?
                            <SelectCustom 
                                name={formData?.format?.ref}
                                label={formData?.format?.label}
                                className={`field field_${formData?.format?.ref}${formData?.format?.error ? " error" : ""}`}
                                value={formData?.format?.value}
                                isRequired={formData?.format?.required}
                                placeholder={formData?.format?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.format?.help}
                                styles={ formStyle }
                                label_icon={<FormatIcon />}
                                tooltip_content={formData?.format?.tooltip}
                                haveError={formData?.format?.error}
                                data={basicData?.formats}
                            /> : ""}

                            {basicData.settings.store.section_fields.cluster.visibility ?
                            <SelectCustom 
                                name={formData?.cluster?.ref}
                                label={formData?.cluster?.label}
                                className={`field field_${formData?.cluster?.ref}${formData?.cluster?.error ? " error" : ""}`}
                                value={formData?.cluster?.value}
                                isRequired={formData?.cluster?.required}
                                placeholder={formData?.cluster?.placeholder}
                                onChange={handleFormElementChange}
                                type="text"
                                help={formData?.cluster?.help}
                                styles={ formStyle }
                                label_icon={<ClusterIcon />}
                                tooltip_content={formData?.cluster?.tooltip}
                                haveError={formData?.cluster?.error}
                                data={basicData?.clusters}
                            /> : ""}
                        
                        </div>
                    </div>
                </div>
                
                {basicData.settings.store.section_fields.additional.visibility ?
                <div className="add_aditional_information">
                    <ButtonTextCustom 
                        className="bt_add_more_info"
                        text={t("texts.+ Agregar Informacion adicional")}
                        onClick={() => handleAddAditionalInformation()}

                        icon={null}
                        background={{ normal: "", hover: "" }}
                        border={{ normal: "", hover: "" }}
                        textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                    />

                    {aditionalInfo &&
                        aditionalInfo.map((el, i) => {
                            return(
                                <AditionalInformationElement 
                                    element={el}
                                    index={i}
                                    aditionalInfo={aditionalInfo}
                                    setAditionalInfo={setAditionalInfo}
                                    className="aditional_information_el"
                                />
                            )
                        })
                    }

                </div> : ""}

                <FormBottomMessage 
                    className="form_error_bottom"
                    haveError={haveErrorForm}
                    text={errorFormMessage.bottom}
                />

                <div className="actions">
                    {!loadingForm ?
                        <ButtonCustom 
                            className="bt_submit"
                            text={`${action === "create" ? `${clientData.name_clients !== "one_sp" ? t("texts.Crear Tienda") : "Crear Estación"}` : `Guardar ${clientData.name_clients !== "one_sp" ? t("texts.Guardar Tienda") : "Estación"}`}`}
                            onClick={handleSubmit}
                            style_type="normal"
                            
                            icon={null}
                            background={{ normal: panColors.primary, hover: panColors.white }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.white, hover: panColors.primary }}
                        />
                        :
                        <div className='loader'>
                            <LoaderComponent size={25} color={panColors.white} />
                        </div>
                    }
                    <ButtonTextCustom 
                        className="bt_cancel"
                        text="Cancelar"
                        onClick={() => { !loadingForm && handleCancel() } }

                        icon={null}
                        background={{ normal: "", hover: "" }}
                        border={{ normal: "", hover: "" }}
                        textcolor={{ normal: panColors.red, hover: panColors.red }}
                        disabled={loadingForm}
                    />
                </div>
                
            </WrapperStyled>
            }
        </>
    )
                
}

export default Form;
