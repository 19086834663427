import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SurveysTable from '../../components/Survey/SurveysTable';
import { useNavigate } from 'react-router-dom';
import { replaceSpecialCharacters,
         isJSON } from '../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../context';

import { HiMagnifyingGlass } from "react-icons/hi2";
import ButtonCustom from '../../components/elements/ButtonCustom';
import InputCustom from '../../components/elements/InputCustom';
import SelectCustom from '../../components/elements/SelectCustom';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`

    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    flex-direction: column;

    >.actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        .bt_create_user{

        }
        .bt_export_user{
            margin-left: 10px;
        }
        .bt_admin_categories{
            margin-left: 10px;
        }
    }

    >.filter_actions{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 35px;
        .field_keywords{
            width: calc(100% - 530px);
        }
        .search{
            max-width: 100%;
            display: flex;
            flex-direction: column;
            width: 100%;
        }
        .filter_option{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            width: 430px;
            .label_title{
                width: 70px;
                font-size: 15px;
                margin-right: 10px;
                margin-left: 15px;
                white-space: nowrap;
                &.order_by{
                    width: 85px;
                }
            }
            .bt_send_filter{
                margin-left: 10px;
            }
            
            .field_category,
            .field_order_by{
                width: 320px;
            }
        }
        .bt_filter{
            align-self: flex-end;
            margin-left: 10px;
        }
    }
    >.content{
        margin-top: 20px;
    }
`


const SurveyContainer = (props) => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    
    const { panColors } = useContext(ConfigurationContext);

    const { ecommerceCategories,
            getEcommerceCategories } = useContext(ContentContext);

    
    //const [ filters, setFilters ] = useState({alphabetical_order: 0, date_order: -1, text_filter: "", category: ""});
    const [ filters, setFilters ] = useState(null);
    const [ category, setCategory ] = useState(1);
    const [ dateOrder, setDateOrder ] = useState("0");
    const [ alphabeticalOrder, setAlphabeticalOrder ] = useState("0");
    const [ orderBy, setOrderBy ] = useState("date|-1");
    const [ categoriesList, setCategoriesList ] = useState([]);
    const [ keywords, setKeywords ] = useState({ value: "", 
                                                 required: false, 
                                                 disabled: false, 
                                                 ref: "keywords", 
                                                 label: t("texts.Palabra / Frase Clave"), 
                                                 placeholder: t("texts.Buscar") + "....",
                                                 help: "",
                                                 error: false})

    const alphabeticalDateOrderList = [{_id: "date|-1", name: t("texts.Fecha mas reciente")}, 
                                       {_id: "date|1", name: t("texts.Fecha mas antigua")}];

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    const handleCreate = () => {
        navigate(`/survey/create`);
    }

    const handleFormElementChange = (name, value) => {
        
        switch (name) {
            case "keywords":
                
                let updateKeywords = {...keywords};
                updateKeywords.value = value;
                setKeywords(updateKeywords);

                break;
            case "order_by":

                setOrderBy(value);
                const orderBy_splitted = value.split("|");
                if(orderBy_splitted[0] == "alphabetical"){
                    setAlphabeticalOrder(orderBy_splitted[1]);
                    setDateOrder(0);
                }
                if(orderBy_splitted[0] == "date"){
                    setAlphabeticalOrder(0);
                    setDateOrder(orderBy_splitted[1]);
                }

                break;
            case "category":

                setCategory(value);

                break;
            default:
                break;
        }
    }

    const handleFilter = (e) => {
        const update_filters = {...filters};
        update_filters.alphabetical_order = alphabeticalOrder;
        update_filters.date_order = dateOrder;
        update_filters.category = category;
        update_filters.text_filter = replaceSpecialCharacters(keywords.value);
        setFilters(update_filters);
        localStorage.setItem('survey_filters', JSON.stringify(update_filters));
    }

    useEffect(() => {

        if(!ecommerceCategories){
            getEcommerceCategories();
        }else{
            setCategoriesList(extractIdAndName(ecommerceCategories));
        }
        return () => {}
    }, [ecommerceCategories])

    const extractIdAndName = (obj, result = []) => {
        if (obj instanceof Array) {
          for (const item of obj) {
            extractIdAndName(item, result);
          }
        } else if (obj instanceof Object) {
          if (obj.hasOwnProperty('_id') && obj.hasOwnProperty('name')) {
            result.push({ _id: obj._id, name: obj.name });
            //result[obj._id] = obj.name;
          }
          for (const key in obj) {
            if (obj[key] instanceof Object || obj[key] instanceof Array) {
              extractIdAndName(obj[key], result);
            }
          }
        }
        return result;
    }

    useEffect(() => {
        
        //localStorage.removeItem('survey_filters');
        if(localStorage.getItem('survey_filters') && isJSON(localStorage.getItem('survey_filters'))){
            const storedFilter = JSON.parse(localStorage.getItem('survey_filters'));

            // console.log("- - - - - - - - - - - - - - - - - - -")
            // console.log(JSON.parse(localStorage.getItem('survey_filters')))
            // console.log("- - - - - - - - - - - - - - - - - - -")

            const update_filters = {...filters};
            update_filters.alphabetical_order = storedFilter.alphabetical_order;
            update_filters.date_order = storedFilter.date_order;
            update_filters.text_filter = storedFilter.text_filter;
            setFilters(update_filters);

            setDateOrder(storedFilter.date_order);
            setAlphabeticalOrder(storedFilter.alphabetical_order);
            
            if(storedFilter.date_order){
                setOrderBy(`date|${storedFilter.date_order}`);
            }
            if(storedFilter.alphabetical_order){
                setOrderBy(`alphabetical|${storedFilter.alphabetical_order}`);
            }
            if(storedFilter.text_filter){
                setKeywords((prevKeywords) => ({ ...prevKeywords, value: storedFilter.text_filter }));
            }
            
        }else{
            
            const update_filters = {...filters};
            update_filters.date_order = -1;
            update_filters.alphabetical_order = 0;
            update_filters.text_filter = "";
            setFilters(update_filters);
            setOrderBy(`date|-1`);

        }
                
        
      return () => {}
    }, [categoriesList])

    if(!filters){
        return null;
    }
    

    return (
        <Wrapper className="dashboard">

            <div className='actions'>
                
                <ButtonCustom 
                    className="bt_create_user"
                    text={t("texts.Crear Encuesta")}
                    onClick={handleCreate}
                    style_type="normal"

                    icon={null}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                />

            </div>

            <div className="filter_actions">

                <InputCustom 
                    name={keywords.ref}
                    label={""}//keywords.label
                    placeholder={keywords?.placeholder}
                    onChange={handleFormElementChange}
                    onKeyDown={null}
                    onKeyUp={null}
                    className={`field field_${keywords.ref}${keywords.error ? " error" : ""}`}
                    type="text"
                    value={keywords.value}
                    help={keywords.help}
                    isRequired={keywords.required}
                    styles={
                        {background_color: `rgba(${panColors.light_white}, 1)`,
                        border_color: `rgba(${panColors.light_white}, 1)`}
                    }
                    icon={<HiMagnifyingGlass />}
                    tooltip_content={keywords.tooltip}
                    haveError={keywords.error}
                />

                <div className="filter_option">   

                    <div className="label_title order_by">
                        {t("texts.Ordenar por")}:
                    </div>

                    <SelectCustom 
                        name={"order_by"}
                        label={""}
                        placeholder={null}
                        onChange={handleFormElementChange}
                        className={`field field_order_by`}
                        type="text"
                        value={orderBy}
                        help={null}
                        isRequired={null}
                        styles={ formStyle }
                        label_icon={null}
                        tooltip_content={null}
                        haveError={false}
                        data={alphabeticalDateOrderList}
                    />

                </div>
                
                <ButtonCustom 
                    className="bt_filter"
                    text={t("texts.Filtrar")}
                    onClick={handleFilter}
                    style_type="normal"

                    icon={null}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                />

            </div>

            <div className="content">
                <SurveysTable filters={filters} /> 
            </div>

        </Wrapper>

    )

}

export default SurveyContainer;
