import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { path } from 'ramda';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ConfigurationContext,
         ContentContext } from '../../../context';

import LoaderComponent from '../../../components/elements/LoaderComponent';
import ButtonCustom from '../../../components/elements/ButtonCustom';
import { BiTrash } from "react-icons/bi";
import { useTranslation } from 'react-i18next';
                        
const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .loader{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin-top: 20px;
        height: 98px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 400px;
        box-sizing: border-box;
        padding: 25px 25px 25px 25px;
        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon{
                width: 75px;
                font-size: 50px;
                color: ${props => `rgb(${props.pancolors.red})`};
                svg{
                    float: left;
                    width: 100%;
                }
            }
            p.title{
                margin: 20px 0 0 0;
                font-weight: 400;
                font-size: 22px;
                line-height: 30px;
                text-align: center;
                .bolder{
                    font-weight: 600;
                }
            }
            p.aclaration{
                margin: 10px 0 0 0;
                font-size: 16px;
                line-height: 20px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            width: 100%;
            margin-top: 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                }
            }
        }
        .actions_dialog{
            display: flex;
            flex-direction: column;
            width: 100%;
            >.bt_submit{
                margin-top: 20px;
            }
            >.bt_cancel{
                margin-top: 10px;
            }
        }
    }
`

const DeleteElement = (props) => {

    const { t } = useTranslation();

    const { panColors } = useContext(ConfigurationContext);
    const { statusComments,
            statusSurvey,
            statusProduct,
            statusUser, 
            statusCourse,
            statusModule,
            statusPost,
            statusStores,
            statusBenefit,
            statusCategory,
            deleteAssessment,
            getEcommerceNewCategory,
            setEcommerceCategories } = useContext(ContentContext);

    const { id,
            open, 
            setOpen,
            title,
            scope,
            setIsDeleted, } = props;
    
    const [ isLoading, setIsLoading ] = useState(false);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleSubmitToDelete = () => {

        let payload = {};
        let deleted = "";
        
        switch (scope) {
            case "users":
                payload = {
                    users_id: id,
                    status: -1
                };
                setIsLoading(true);
                deleted = statusUser(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                    
                })
                
                break;
            case "assessment":
                payload = {
                    id_assessment: id,
                    status: -1
                };

                setIsLoading(true);
                deleted = deleteAssessment(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                    
                })
                
                break;
            case "courses":
                payload = {
                    courses_id: id,
                    status: -1
                };
              
                setIsLoading(true);
                deleted = statusCourse(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                })
                break;
            case "modules":
                payload = {
                    content_id: id,
                    status: -1
                };
              
                setIsLoading(true);
                deleted = statusModule(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                })
                
                break;
            case "posts":
                payload = {
                    post_id: id,
                    status: -1
                };
                
                setIsLoading(true);
                deleted = statusPost(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                })
                
                break;
            case "stores":
                payload = {
                    stores_id: id,
                    status: -1
                };
                
                setIsLoading(true);
                deleted = statusStores(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                })
                
                break;
            case "benefit":
                payload = {
                    benefit_id: id,
                    status: -1
                };
                
                setIsLoading(true);
                deleted = statusBenefit(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                })
                
                break;
            case "product":
                payload = {
                    categories_id: id,
                    status: -1
                };
                
                setIsLoading(true);
                deleted = statusCategory(payload);
                deleted.then((res) => {

                    const response = getEcommerceNewCategory();
                    response.then(res => {

                        setEcommerceCategories(path(['data', 'single', 'categories'], res));
                        setIsDeleted(true);

                    })

                    
                })
                
                break;
            case "products":
                payload = {
                    product_id: id,
                    status: -1
                };
                
                setIsLoading(true);
                deleted = statusProduct(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                })
                
                break;
            case "survey":
                payload = {
                    survey_id: id,
                    status: -1
                };
                
                setIsLoading(true);
                deleted = statusSurvey(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                })
                
                break;
            case "comments":
                payload = {
                    comments_ids: id,
                    status: -1
                };
                
                setIsLoading(true);
                deleted = statusComments(payload);
                deleted.then((res) => {
                    setIsDeleted(true);
                })
                
                break;
                
            default:
                break;
        }
    }

    useEffect(() => {
      return () => {
        if(!open){
            setIsLoading(false);
        }
      }
    }, [open])
    

    return (
        
            <DialogStyled
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                maxWidth="xs"
                pancolors={panColors}
            >
                <div className="inner_dialog">
                
                    
                    <div className="header_dialog">
                        <div className="icon">
                            <BiTrash />
                        </div>
                        <p className="title" dangerouslySetInnerHTML={{__html:title}}></p>
                        <p className="aclaration">{t("texts.Esta accion no puede deshacerse")}</p>
                    </div>

                    {isLoading ? 
                        <div className='loader'>
                            <LoaderComponent />
                        </div>
                    :
                        <div className="actions_dialog">
                            <ButtonCustom 
                                className="bt_submit"
                                text={t("texts.Eliminar")}
                                onClick={() => handleSubmitToDelete()}
                                style_type="normal"
                                
                                icon={null}
                                background={{ normal: panColors.red, hover: panColors.white }}
                                border={{ normal: panColors.red, hover: panColors.red }}
                                textcolor={{ normal: panColors.white, hover: panColors.red }}
                            />
                            <ButtonCustom 
                                className="bt_cancel"
                                text={t("texts.Cancelar")}
                                onClick={() => setOpen(false)}
                                style_type="invert"

                                icon={null}
                                background={{ normal: panColors.white, hover: panColors.primary }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.primary, hover: panColors.white }}
                            />
                        </div>
                    }
                </div>
            </DialogStyled>
    );
}


export default DeleteElement;