import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import InputCustom from '../elements/InputCustom';
import { dataObjectOpenAnswer, } from '../../helpers/functions';
import { ConfigurationContext } from '../../context';
import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    .field_answer_text{
        margin-top: 20px;
    }
    .title{
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
        margin-top: 35px;
        margin-bottom: 20px;
    }
    .field_title{
        margin-top: 25px;
    }
    .field_question_text{
        margin-top: 25px;
    }
    .box_options{
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        .option{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 20px;
            border: 2px solid ${props => `rgb(${props.pancolors.dark_gray})`};
            border-radius: 8px;
            box-sizing: border-box;
            padding: 10px 15px;
            margin-left: 10px;
            color: ${props => `rgb(${props.pancolors.dark_gray})`};
            font-weight: 600;
            &.exist{
                border-color: ${props => `rgb(${props.pancolors.dots})`};
                background-color: ${props => `rgb(${props.pancolors.dots})`};
                color: #FFFFFF;
            }
            .close{
                cursor: pointer;
                :hover{
                    color: ${props => `rgb(${props.pancolors.red})`};
                }
            }
            :first-child {
                margin-left: 0;
            }
            p{
                margin: 0 0 0 5px;
            }
        }
    }
`

const SingleChoice = (props) => {

    const { t } = useTranslation();

    const { panColors } = useContext(ConfigurationContext);

    const { questions,
            questionSelected,
            setQuestionSelected,
            lang = null,} = props;
    
    const [ questionsList, setQuestionsList ] = useState([]);
    const [ errorWords, setErrorWords ] = useState([]);
    const [ errorTimeOut, setErrorTimeOut ] = useState(null);

    const [ answerText, setAnswerText ] = useState({ 
        value: "", 
        required: true, 
        disabled: false, 
        ref: "answer_text", 
        label: "Ingrese con ENTER, las palabras clave para considerar esta pregunta aprobada", 
        placeholder: "Ingresar respuesta",
        help: "Min 1 Max 20 palabras",
        error: false 
    });

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
            
    const handleFormElementChange = (name, value) => {

        let question_selected_update = {...questionSelected};
        
        switch (name) {
            case 'title':
                question_selected_update.translations[lang].title.value = value;
                break;
            case 'answer_text':
                let update = {...answerText};
                update.value = value;
                setAnswerText(update);
                break;
            default:
                break;
        }

        setQuestionSelected(question_selected_update);

    }

    const handleKeyDown = (key, name, value) => {
        if(errorTimeOut){
            clearTimeout(errorTimeOut);
        }
        setErrorWords([]);

        if (key === 'Enter') {

            const exist = handleWordExist(value);

            if(!exist){

                let update = {...answerText};
                update.value = "";
                setAnswerText(update);
                
                let question_selected_update = {...questionSelected};
                question_selected_update.translations[lang].secondary_actions.push(dataObjectOpenAnswer({id: uuid(), correct: true, label: value}));

                setQuestionSelected(question_selected_update);

            }else{

                setErrorWords(words => [...words, value]);
                const timeout = setTimeout(() => {
                    setErrorWords([]);
                }, 2000);
                setErrorTimeOut(timeout);

            }
        }
    }

    const handleWordExist = (word) => {

        let exist = false;
        questionSelected.translations[lang].secondary_actions.map((w, i) => {
            if(w.label.value === word){
                exist = true;
            }
        })
        return exist;

    }
    
    const handleRemoveWord = (_word) => {
        let remove_word = {...questionSelected};
        const filtered = questionSelected.translations[lang].secondary_actions.filter(word => word.id.value !== _word.id.value);
        remove_word.translations[lang].secondary_actions = filtered;
        setQuestionSelected(remove_word);

    }

    useEffect(() => {
      
        if(questions){
            const questions_updates = questions.map((question, index) => {
                return {_id: question._id, name: (index + 1)}
            })
            setQuestionsList(questions_updates);
        }
    
      return () => {}
    }, [questions])
    
    

    return (
        <WrapperStyled pancolors={panColors}>

            <InputCustom 
                name={questionSelected.translations[lang].title.ref}
                label={t("texts."+questionSelected.translations[lang].title.label)}
                placeholder={t("texts."+questionSelected.translations[lang].title.placeholder)}
                onChange={handleFormElementChange}
                onKeyDown={null}
                className={`field field_${questionSelected.translations[lang].title.ref}${questionSelected.translations[lang].title.error ? " error" : ""}`}
                type="text"
                value={questionSelected.translations[lang].title.value}
                help={questionSelected.translations[lang].title.help}
                isRequired={questionSelected.translations[lang].title.required}
                styles={ formStyle }
                label_icon={null}
                tooltip_content={null}
                haveError={questionSelected.translations[lang].title.error}
            />
            <InputCustom 
                name={answerText.ref}
                label={t("texts."+answerText.label)}
                placeholder={t("texts."+answerText.placeholder)}
                onChange={handleFormElementChange}
                onKeyDown={handleKeyDown}
                className={`field field_${answerText.ref}${questionSelected.translations[lang].secondary_actions_error ? " error" : ""}`}
                type="text"
                value={answerText.value}
                help={answerText.help}
                isRequired={answerText.required}
                styles={ formStyle }
                label_icon={null}
                tooltip_content={null}
                haveError={questionSelected.translations[lang].secondary_actions_error}
            />

            <div className="box_options">
                
                {questionSelected.translations[lang].secondary_actions.map((word, index) => {
                    return(
                        <div key={`w-id-${index}`} className={`option${errorWords.includes(word.label.value) ? " exist" : ""}`}>
                            <div className="close" onClick={() => handleRemoveWord(word)}>x</div>
                            <p>{ word.label.value }</p>
                        </div>
                    )
                })}
                
            </div>
            
        </WrapperStyled>
    );
}

export default React.memo(SingleChoice);