import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ConfigurationContext,
         ContentContext } from '../../../context';
         
import ButtonCustom from '../../../components/elements/ButtonCustom';

import { ListCheckIcon,
         CountryIcon,
         RoleIcon,
         RegionIcon,
         ClusterIcon,
         FormatIcon } from '../../../helpers/icons';

import { getNameFromDataById } from '../../../helpers/functions';
import { useTranslation } from 'react-i18next';
                        
const DialogStyled = styled(Dialog)`
    .MuiDialog-paper{
        border-radius: 8px;
    }
    .inner_dialog{
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 700px;
        box-sizing: border-box;
        padding: 35px 30px 35px 30px;

        .header_dialog{
            display: flex;
            flex-direction: column;
            align-items: center;
            .icon{
                width: 75px;
                svg{
                    float: left;
                    width: 100%;
                }
            }
            p.title{
                margin: 20px 0 0 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
            }
            p.description{
                margin: 5px 0 0 0;
                font-size: 16px;
                line-height: 20px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
            }
        }
        .content_dialog{
            display: flex;
            width: 100%;
            box-sizing: border-box;
            padding: 35px 20px;
            .field{
                width: 100%;
                .datetime_activate_at{
                    width: 100%;
                    .MuiOutlinedInput-notchedOutline{
                        border-color: rgba(0, 0, 0, 0.23);
                    }
                    &.error{
                        .MuiOutlinedInput-notchedOutline{
                            border-color: ${props => `rgb(${props.pancolors.red})`}!important;
                        }
                    }
                }
            }

            .segments{
                width: 100%;
                display: grid; 
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                .segment{
                    display: flex;
                    flex-direction: column;
                    .segment_head{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        .segment_icon{ 
                            display: flex;
                            svg{
                                fill: ${props => `rgb(${props.pancolors.dark_gray})`};
                            }
                        }
                        .segment_name{
                            font-weight: 600;
                            margin-left: 10px;
                            color: ${props => `rgb(${props.pancolors.black_overwhite})`};
                        }
                    }
                    .text{
                        margin-left: 36px;
                        color: ${props => `rgb(${props.pancolors.dark_gray})`};
                    }
                }
            }

        }
        .actions_dialog{
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            >.bt_submit{
                //margin-top: 20px;
            }
            >.bt_cancel{
                margin-left: 10px;
            }
            >.bt_callback{
                margin-left: 10px;
            }
        }
    }
`

const Confirmation = (props) => {

    const { t } = useTranslation();
    const { panColors } = useContext(ConfigurationContext);
    const { basicData } = useContext(ContentContext);

    const { description,
            type,
            data,
            open, 
            setOpen,
            handleSubmit,
            handleCancelSubmit,
            handleCallback,
            segments,
            setSegments, } = props;
            
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [ dataToShow, setDataToShow ] = useState([]);

    const handleClose = () => {
        handleCancelSubmit();
        setOpen(false);
    };

    const handleSubmitConfirmation = () => {
        handleSubmit();
        setOpen(false);
    }

    useEffect(() => {
        if(basicData && data && type){
            let date_to_show = {};
            if(type == 'course'){
                if(data.countries){
                    let temp = data.countries.map((item, index) => {
                        return getNameFromDataById(basicData.countries, item);
                    })
                    date_to_show['countries'] = temp;
                }
                if(data.areas){
                    let temp = data.areas.map((item, index) => {
                        return getNameFromDataById(basicData.areas, item);
                    })
                    date_to_show['areas'] = temp;
                }
                if(data.clusters){
                    let temp = data.clusters.map((item, index) => {
                        return getNameFromDataById(basicData.clusters, item);
                    })
                    date_to_show['clusters'] = temp;
                }
                if(data.departments){
                    let temp = data.departments.map((item, index) => {
                        return getNameFromDataById(basicData.departments, item);
                    })
                    date_to_show['departments'] = temp;
                }
                if(data.formats){
                    let temp = data.formats.map((item, index) => {
                        return getNameFromDataById(basicData.formats, item);
                    })
                    date_to_show['formats'] = temp;
                }
                if(data.idioms){
                    let temp = data.idioms.map((item, index) => {
                        return getNameFromDataById(basicData.idioms, item);
                    })
                    date_to_show['idioms'] = temp;
                }
                if(data.regions){
                    let temp = data.regions.map((item, index) => {
                        return getNameFromDataById(basicData.regions, item);
                    })
                    date_to_show['regions'] = temp;
                }
                if(data.retailers){
                    let temp = data.retailers.map((item, index) => {
                        return getNameFromDataById(basicData.retailers, item);
                    })
                    date_to_show['retailers'] = temp;
                }
                if(data.roles){
                    let temp = data.roles.map((item, index) => {
                        return getNameFromDataById(basicData.roles, item);
                    })
                    date_to_show['roles'] = temp;
                }
                if(data.achievements){
                    let temp = data.achievements.map((item, index) => {
                        return getNameFromDataById(basicData.achievements, item);
                    })
                    date_to_show['achievements'] = temp;
                }
                if(data.flags){
                    let temp = data.flags.map((item, index) => {
                        return getNameFromDataById(basicData.flags, item);
                    })
                    date_to_show['flags'] = temp
                }
                
            }
            setDataToShow(date_to_show)
        }

      return () => {}
    }, [basicData, data, type])

    useEffect(() => {
        
        if(segments){
            if(!Object.keys(segments).length){
                
                setSegments({ countries: [],
                            areas: [],
                            roles: [],
                            regions: [],
                            clusters: [],
                            retailers: [],
                            formats: [],
                            idioms: [],
                            achievements: [],
                            departments: [],
                            flags: [] })

            }
        }
    
      return () => {}
    }, [segments])
    

    return (
        
            <DialogStyled
                fullScreen={fullScreen}
                open={open}
                aria-labelledby="responsive-dialog-title"
                maxWidth="md"
                pancolors={panColors}
            >
                <div className="inner_dialog">

                    <div className="header_dialog">
                        <div className="icon">
                            <ListCheckIcon />
                        </div>
                        <p className="title">{t("texts.Confirmar Publicar este nuevo contenido con esta segmentacion")}</p>
                        <p className="description">{ description }</p>
                    </div>

                    <div className='content_dialog'>
                        {type == 'course' ? 

                            <div className='segments'>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><CountryIcon /></div>
                                        <div className='segment_name'>{t("texts.Paises")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.countries?.length ? dataToShow.countries.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><CountryIcon /></div>
                                        <div className='segment_name'>{t("texts.Area")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.areas?.length ? dataToShow.areas.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><RoleIcon /></div>
                                        <div className='segment_name'>{t("texts.Roles")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.roles?.length ? dataToShow.roles.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><RoleIcon /></div>
                                        <div className='segment_name'>{t("texts.Idiomas")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.idioms?.length ? dataToShow.idioms.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><RegionIcon /></div>
                                        <div className='segment_name'>{t("texts.Region")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.regions?.length ? dataToShow.regions.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><ClusterIcon /></div>
                                        <div className='segment_name'>{t("texts.Clusters")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.clusters?.length ? dataToShow.clusters.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><ClusterIcon /></div>
                                        <div className='segment_name'>{t("texts.Retailers")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.retailers?.length ? dataToShow.retailers.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><FormatIcon /></div>
                                        <div className='segment_name'>{t("texts.Formato")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.formats?.length ? dataToShow.formats.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><RoleIcon /></div>
                                        <div className='segment_name'>{t("texts.Departamentos")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.departments?.length ? dataToShow.departments.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><RoleIcon /></div>
                                        <div className='segment_name'>{t("texts.Logros")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.achievements?.length ? dataToShow.achievements.join(", ") : t("texts.Todos") }</div>
                                </div>
                                <div className='segment'>
                                    <div className='segment_head'>
                                        <div className='segment_icon'><RoleIcon /></div>
                                        <div className='segment_name'>{t("texts.Banderas")}</div>
                                    </div>
                                    <div className='text'>{ dataToShow?.flags?.length ? dataToShow.flags.join(", ") : t("texts.Todos") }</div>
                                </div>
                            </div>

                        :
                        <></>
                        }
                    </div>

                    <div className="actions_dialog">
                        <ButtonCustom 
                            className="bt_submit"
                            text={t("texts.Confirmar")}
                            onClick={handleSubmitConfirmation}
                            style_type="normal"
                            
                            icon={null}
                            background={{ normal: panColors.primary, hover: panColors.white }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.white, hover: panColors.primary }}
                        />
                        {handleCallback && 
                            <ButtonCustom 
                                className="bt_callback"
                                text={t("texts.Editar Segmentacion")}
                                onClick={handleCallback}
                                style_type="normal"
                                
                                icon={null}
                                background={{ normal: panColors.primary, hover: panColors.white }}
                                border={{ normal: panColors.primary, hover: panColors.primary }}
                                textcolor={{ normal: panColors.white, hover: panColors.primary }}
                            />
                        }
                        <ButtonCustom 
                            className="bt_cancel"
                            text={t("texts.Cancelar")}
                            onClick={handleClose}
                            style_type="invert"

                            icon={null}
                            background={{ normal: panColors.white, hover: panColors.primary }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.primary, hover: panColors.white }}
                        />
                    </div>
                </div>

            </DialogStyled>
    );
}


export default Confirmation;