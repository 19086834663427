import React, { useContext } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';
import ButtonCustom from '../../components/elements/ButtonCustom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 100%;

    >.inner_success{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 500px;
        .image{
            width: 500px;
            height: 377px;
            img{
                float: left;
                width: 100%;
                height: 100%;
            }
        }
        .box_texts{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 400px;
            margin-top: 20px;
            .congrats_text{
                font-size: 24px;
                font-weight: 700;
                color: ${props => `rgb(${props.panColors.green})`};
            }
            .action_success_text{
                font-size: 20px;
                font-weight: 700;
                color: ${props => `rgb(${props.panColors.black})`};
                margin-top: 10px;
                text-align: center;
            }

            .result_success_text{
                font-size: 16px;
                font-weight: 400;
                text-align: center;
                color: ${props => `rgb(${props.panColors.black_overwhite})`};
                margin-top: 10px;
            }
        }
        .actions{
            display: flex;
            flex-direction: column;
            width: 350px;
            margin-top: 25px;
            .bt{
                width: 100%;
                margin-top: 10px;
            }
        }
    }
    
`

const SuccessForm = (props) => {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { panColors } = useContext(ConfigurationContext);

    const { image,
            actionSuccessText,
            resultSuccessText,
            createNew,
            createContent,
            createEvaluation,
            createSegmentation,
            backTo,
            setSuccess,
            buttonBackToListText,
            buttonCreateNewText,
            buttonCreateContentText,
            buttonBackToListStyle,
            buttonCreateNewStyle,
            buttonCreateContentStyle,
            buttonCreateEvaluationText,
            buttonCreateSegmentationText } = props;
    
    const goToRoute = (route) => {
        setSuccess(false)
        navigate(route);
    }

    return (
        <WrapperStyled
            panColors={panColors}
        >
            <div className="inner_success">
                <div className="image">
                    <img src={image} />
                </div>
                <div className="box_texts">
                    <div className="congrats_text">
                        { t("texts.Felicitaciones") }
                    </div>
                    <div className="action_success_text">
                        {actionSuccessText}
                    </div>
                    {resultSuccessText ? 
                    <div className="result_success_text">
                        {resultSuccessText}
                    </div>
                    :""}
                </div>
                <div className="actions">

                    {buttonCreateContentText != null &&
                        <ButtonCustom 
                            className="bt"
                            text={buttonCreateContentText}
                            onClick={() => goToRoute(createContent)}
                            style_type="invert"

                            icon={null}
                            background={{ normal: buttonCreateContentStyle === "invert" ? panColors.white : panColors.primary, 
                                          hover: buttonCreateContentStyle === "invert" ? panColors.primary : panColors.white}}
                            border={{ normal: panColors.primary, 
                                      hover: panColors.primary }}
                            textcolor={{ normal: buttonCreateContentStyle === "invert" ? panColors.primary : panColors.white, 
                                         hover: buttonCreateContentStyle === "invert" ? panColors.white : panColors.primary }}
                        />
                    }
                    {buttonCreateEvaluationText != null &&
                        <ButtonCustom 
                            className="bt"
                            text={buttonCreateEvaluationText}
                            onClick={() => goToRoute(createEvaluation)}
                            style_type="invert"

                            icon={null}
                            background={{ normal: buttonCreateContentStyle === "invert" ? panColors.white : panColors.primary, 
                                          hover: buttonCreateContentStyle === "invert" ? panColors.primary : panColors.white}}
                            border={{ normal: panColors.primary, 
                                      hover: panColors.primary }}
                            textcolor={{ normal: buttonCreateContentStyle === "invert" ? panColors.primary : panColors.white, 
                                         hover: buttonCreateContentStyle === "invert" ? panColors.white : panColors.primary }}
                        />
                    }
                    {buttonCreateSegmentationText != null &&
                        <ButtonCustom 
                            className="bt"
                            text={buttonCreateSegmentationText}
                            onClick={() => goToRoute(createSegmentation)}
                            style_type="invert"

                            icon={null}
                            background={{ normal: buttonCreateContentStyle === "invert" ? panColors.white : panColors.primary, 
                                          hover: buttonCreateContentStyle === "invert" ? panColors.primary : panColors.white}}
                            border={{ normal: panColors.primary, 
                                      hover: panColors.primary }}
                            textcolor={{ normal: buttonCreateContentStyle === "invert" ? panColors.primary : panColors.white, 
                                         hover: buttonCreateContentStyle === "invert" ? panColors.white : panColors.primary }}
                        />
                    }
                    
                    <ButtonCustom 
                        className="bt"
                        text={buttonBackToListText}
                        onClick={() => goToRoute(backTo)}
                        style_type="normal"
                        
                        icon={null}
                        background={{ normal: buttonBackToListStyle === "invert" ? panColors.white : panColors.primary, 
                                      hover: buttonBackToListStyle === "invert" ? panColors.primary : panColors.white}}
                        border={{ normal: panColors.primary, 
                                  hover: panColors.primary }}
                        textcolor={{ normal: buttonBackToListStyle === "invert" ? panColors.primary : panColors.white, 
                                     hover: buttonBackToListStyle === "invert" ? panColors.white : panColors.primary }}
                    />
                    {buttonCreateNewText != null &&
                        <ButtonCustom 
                            className="bt"
                            text={buttonCreateNewText}
                            onClick={() => goToRoute(createNew)}
                            style_type="invert"

                            icon={null}
                            background={{ normal: buttonCreateNewStyle === "invert" ? panColors.white : panColors.primary, 
                                          hover: buttonCreateNewStyle === "invert" ? panColors.primary : panColors.white}}
                            border={{ normal: panColors.primary, 
                                      hover: panColors.primary }}
                            textcolor={{ normal: buttonCreateNewStyle === "invert" ? panColors.primary : panColors.white, 
                                         hover: buttonCreateNewStyle === "invert" ? panColors.white : panColors.primary }}
                        />
                    }

                </div>
            </div>

        </WrapperStyled>
    )

}

export default SuccessForm;