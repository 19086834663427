import React, { useEffect, Suspense, useContext, Fragment, useState, useCallback } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';

import { ConfigurationContext,
         ContentContext } from './context';
         
import { resolveUrl, resolveComponentName } from './helpers/functions';
import LoaderComponent from './components/elements/LoaderComponent';
import Dashboard from './pages/Dashboard';
import EvaluationsCreate from './pages/Evaluations/Create';
import EvaluationsView from './pages/Evaluations/View';
import EvaluationsEdit from './pages/Evaluations/Edit';

import SegmentationCreate from './pages/Segmentation/Create';
import SegmentationEdit from './pages/Segmentation/Edit';

import CountrySelect from './pages/CountrySelect';

import Survey from './pages/Survey';
import SurveyCreate from './pages/Survey/Create';

import Users from './pages/Users';
import UsersEdit from './pages/Users/Edit';
import UsersView from './pages/Users/View';
import UsersCreate from './pages/Users/Create';

import VouchersCreate from './pages/Benefits/Vouchers/Create';
import VouchersEdit from './pages/Benefits/Vouchers/Edit';
import VouchersView from './pages/Benefits/Vouchers/View';
 
import Stores from './pages/Stores';
import CreateStore from './pages/Stores/Create';
import EditStore from './pages/Stores/Edit';
import ViewStore from './pages/Stores/View';
import StoresCampaigns from './pages/Stores/Campaigns';
import StoresCampaignsAudits from './pages/Stores/Campaigns/Audits';

import CommentsPosts from './pages/Posts/Comments';

// StoresCreate StoresEdit StoresView

// const StoresComponent = loadComponent("Stores");
//             const StoresEditComponent = loadComponent("Stores/Edit");
//             const StoresViewComponent = loadComponent("Stores/View");
//             const StoresTestComponent = loadComponent("Stores/Test");
//             const StoresCreateComponent = loadComponent("Stores/Create");

import './App.css';

const ProtectedRoutes = () => {
  
    const navigate = useNavigate();
    const { search, pathname } = useLocation();

    const { clientData, 
            panColors,
            userCountrySelected, 
            setUserCountrySelected } = useContext(ConfigurationContext);

    const { basicData, 
            setBasicData,   
            menuData,
            setMenuData } = useContext(ContentContext);
    const [ showImportOption, setShowImportOption ] = useState(null);

    const [ menuItems, setMenuItems ] = useState(null);
    const [ show, setShow ] = useState(false);

    useEffect(() => {
        if(basicData){
            if(basicData.user_countries.length > 1){
                if(pathname !== '/country' && pathname !== '/'){
                    setUserCountrySelected(basicData.country);
                }
                setShow(true);
            }else{
                if(basicData.user_countries.length === 1){
                    if(basicData.country.id_country !== undefined){
                        setUserCountrySelected(basicData.country);
                        //console.log("basicData", basicData.settings.options)
                        const menu_user = basicData.settings.options.filter(op => op.id === "1");
                        if(menu_user.length){
                            navigate("/users");
                        }else{
                            navigate("/reporting");
                        }
                        setShow(true);
                    }else{
                        setShow(true);
                    }
                }
            }
        }

        return () => {}
    }, [basicData])
  
    useEffect(() => {
        console.log("clientData", clientData)
        if(clientData !== null){
            if("can_use_import" in clientData){
                if(clientData.can_use_import){
                    const option_import = {icon: "", id: "999", label: "Import"};
                    let basicData_update = {...basicData};
                    basicData_update.settings.options.push(option_import);
                    setBasicData(basicData_update);
                }
                setShowImportOption(true);
            }else{
                setShowImportOption(false);
            }
            //setShowImportOption();
        }
    
      return () => {}
    }, [clientData])
    
    useEffect(() => {
      console.log("basicData", basicData)
    
      return () => {}
    }, [basicData])
    

    if(!show || showImportOption === null){
        return <div className='loader'>
                <LoaderComponent size={40} color={panColors.dots} />
            </div>;
    }

  
  return (
    <Routes>
        {/*<Route path={`/dashboard`} element={<Dashboard />} exact />*/}

        {menuData && menuData.length > 0 &&
            menuData.map((el, i) => {
                //console.log("el", el)
            return (
                resolveComponentName(el)
            )
            })
        }

        <Route 
            path={"/users"}//${resolveUrl(item.label)}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <Users />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/users/create"}//${resolveUrl(item.label)}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <UsersCreate />
                </Suspense>} 
            exact 
        />
        <Route 
            //path={`/${resolveUrl(item.label)}/:slug/:id`}
            path={"/users/edit/:id"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <UsersEdit />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/users/view/:id"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <UsersView />
                </Suspense>} 
            exact 
        />






        <Route 
            path={"/country"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <CountrySelect />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/survey"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <Survey />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/survey/create"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <SurveyCreate />
                </Suspense>} 
            exact 
        />


        <Route 
            path={"/course/:id_course/evaluation/create"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <EvaluationsCreate />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/course/:id_course/evaluation/:id_assessment/view"}   
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <EvaluationsView />
                </Suspense>} 
            exact 
        />
        <Route        
            path={"/course/:id_course/evaluation/:id_assessment/edit"}     
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <EvaluationsEdit />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/module/:id_module/evaluation/create"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <EvaluationsCreate />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/module/:id_module/evaluation/:id_assessment/view"}   
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <EvaluationsView />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/module/:id_module/evaluation/:id_assessment/edit"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <EvaluationsEdit />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/evaluation/:id_assessment/segmentation/create"}   
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <SegmentationCreate />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/evaluation/:id_assessment/segmentation/edit"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <SegmentationEdit />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/course/:id_course/segmentation/create"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <SegmentationCreate />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/course/:id_course/segmentation/edit"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <SegmentationEdit />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/posts/:id_post/segmentation/edit"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <SegmentationEdit />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/benefit/:id_benefit/segmentation/edit"}        
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <SegmentationEdit />
                </Suspense>} 
            exact 
        />

        <Route 
            path={"/benefits/vouchers/create"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <VouchersCreate />
                </Suspense>} 
            exact 
            />
        <Route 
            path={"/benefits/vouchers/:id_benefit/edit"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <VouchersEdit />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/benefits/vouchers/:id_benefit/view"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <VouchersView />
                </Suspense>} 
            exact 
        />



        <Route 
            path={"/stores"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <Stores />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/stores/create"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <CreateStore />
                </Suspense>} 
            exact 
        />
        <Route 
            //path={`/${resolveUrl(item.label)}/:slug/:id`}
            path={"/stores/:id_store/edit"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <EditStore />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/stores/:id_store/view"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <ViewStore />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/stores/campaigns"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <StoresCampaigns />
                </Suspense>} 
            exact 
        />
        <Route 
            path={"/stores/campaigns/audits/:id_audit"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <StoresCampaignsAudits />
                </Suspense>} 
            exact 
        />


        <Route 
            path={"/posts/comments"}
            element={
                <Suspense fallback={<LoaderComponent />}>
                    <CommentsPosts />
                </Suspense>} 
            exact 
        />

      {/*
      <Route 
          path={"/module/:id_module/segmentation/create"}        
          element={
              <Suspense fallback={<LoaderComponent />}>
                  <SegmentationCreate />
              </Suspense>} 
          exact 
      />
      <Route 
          path={"/module/:id_module/segmentation/edit"}        
          element={
              <Suspense fallback={<LoaderComponent />}>
                  <SegmentationEdit />
              </Suspense>} 
          exact 
      />
      */}
    </Routes>
  );
}

export default ProtectedRoutes;
