import React, { useState, useContext, useEffect,useRef } from 'react';
import styled from 'styled-components';

import { ConfigurationContext } from '../../context';

import LabelCustom from './LabelCustom';
import InputCustom from './InputCustom';
import ButtonCustom from './ButtonCustom';

import { TrashIcon } from '../../helpers/icons';

import Select from 'react-select';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-end;
    width: 100%;
    
    background-color: ${props => `rgb(${props.panColors.light_white})`};
    box-sizing: border-box;
    .product_select{
        width: 540px;
        .select__control{
            height: 43px!important;
            border: 1px solid rgb(186, 186, 194)!important;
        }
        .select__menu{
            
            .select__menu-list{
                max-height: 170px!important;
            }
        }
    }
    .fields{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: calc(100% - 60px);
        padding: 25px 30px 25px 30px;
        .info_title,
        .info_text{
            width: calc(50% - 10px);
        }
        .field_min{
            width: 150px;
        }
        .field_max{
            width: 150px;
        }
    }
    .actions{
        box-sizing: border-box;
        min-height: 100%;
        padding: 15px 15px 15px 0;
        .bt_submit{
            min-height: 100%;
            box-sizing: border-box;
            padding: 0 15px 0 15px;
            .icon{
                margin-right: 0!important;
                svg{
                    width: 23px;
                    height: 23px;
                }
            }
        }
    }
`

const AditionalInformationElement = (props) => {

    const { t } = useTranslation();
    
    const { panColors } = useContext(ConfigurationContext);

    const { className,
            index,
            element,
            products,
            setProducts,
            options,
            setOptions,
            minGeneralSaved,
            maxGeneralSaved } = props;

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
    
    const [isClearable, setIsClearable] = useState(true);
    const [isSearchable, setIsSearchable] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRtl, setIsRtl] = useState(false);

    const [ max, setMax ] = useState(0);
    const [ min, setMin ] = useState(0);
    const [ fieldError, setFieldError ] = useState([]);
    
    let timerDop = useRef();

    const handleInputChange = (e, val) => {
    }
    const handleOnKeyDown = (e, val) => {


        if(timerDop.current !== null){
            clearTimeout(timerDop.current);
        }
        
        timerDop.current = setTimeout(() => {

            const options_update = [...options];
            options_update.push({ value: 'xxx', label: 'Xxxxx', color: '#00875A' });
            setOptions(options_update);
            
        }, 3000);

    }

    const handleOnKeyUp = (val) => {
    }

    const handleRemoveElement = (id) => {
        const elements = products.filter(el => el.id !== id);
        setProducts(elements);
    }
    
    const handleFormElementChange = (name, value) => {
        const products_update = [...products];

        switch (name) {
            case 'min':
                setMin(value);
                products_update[index].min = value;
                break;
            case 'max':
                setMax(value);
                products_update[index].max = value;
                break;
            default:
                break;
        }

        setProducts(products_update);
        
        

    }

    const handleChange = (selectedOption) => {

        const products_update = [...products];
        if(selectedOption){
            products_update[index].p_id = selectedOption.value;
        }else{
            products_update[index].p_id = '';
        }
        setProducts(products_update);
        
    }

    useEffect(() => {

        if(maxGeneralSaved.length && maxGeneralSaved.length){

            const products_update = [...products];
            products_update[index].min = minGeneralSaved;
            products_update[index].max = maxGeneralSaved;
            setProducts(products_update);

        }

    return () => {}
    }, [])
    
    const getSelectedOption = () => {
        
        const elements = options.filter(option => option.value === products[index].p_id);
        return elements[0];
    }

    if(!options){
        return null;
    }
    
    return (
        <Wrapper
            className={className}
            panColors={panColors}
        >
            
            <div className="fields">

                <div className='field product'>
                <LabelCustom 
                    name="label_product"
                    label={t("texts.Producto")}
                    onChange={handleFormElementChange}
                    className="field field_product"
                    styles={ formStyle }
                    label_icon={null}
                />
                <Select
                    className="product_select"
                    classNamePrefix="select"
                    defaultValue={getSelectedOption()}
                    isDisabled={isDisabled}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    isRtl={isRtl}
                    isSearchable={isSearchable}
                    name="color"
                    options={options}
                    onInputChange={handleInputChange}
                    onKeyDown={handleOnKeyDown}
                    onKeyUp={handleOnKeyUp}
                    onChange={handleChange}
                    placeholder={t("texts.Buscar por nombre o código EAN") + "..."}
                />
                </div>
                
                <InputCustom 
                    name={"min"}
                    label={t("texts.Minimo")}
                    placeholder={t("texts.Ingresar link") + "..."}
                    onChange={handleFormElementChange}
                    onKeyDown={null}
                    className={`field field_min ${fieldError['min'] ? " error" : ""}`}
                    type="text"
                    value={products[index].min}
                    help={""}
                    isRequired={false}
                    styles={ formStyle }
                    label_icon={null}
                    tooltip_content={null}
                    haveError={fieldError['min'] ? true : false}
                />

                <InputCustom 
                    name={"max"}
                    label={t("texts.Maximo")}
                    placeholder={t("texts.Ingresar link") + "..."}
                    onChange={handleFormElementChange}
                    onKeyDown={null}
                    className={`field field_max ${fieldError['max'] ? " error" : ""}`}
                    type="text"
                    value={products[index].max}
                    help={""}
                    isRequired={false}
                    styles={ formStyle }
                    label_icon={null}
                    tooltip_content={null}
                    haveError={fieldError['max'] ? true : false}
                />
            </div>
            
            <div className='actions'>
                <ButtonCustom 
                    className="bt_submit"
                    text=""
                    onClick={() => handleRemoveElement(element.id)}
                    style_type="normal"
                    
                    icon={<TrashIcon />}
                    background={{ normal: panColors.white, hover: panColors.white }}
                    border={{ normal: panColors.red, hover: panColors.primary }}
                    textcolor={{ normal: panColors.red, hover: panColors.primary }}
                />
            </div>

        </Wrapper>

    )

}

export default AditionalInformationElement;