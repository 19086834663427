import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';

import { ContentContext,
         ConfigurationContext } from '../../context';

import TableSurveys from './../elements/DataTable/TableSurveys';
import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`
    background-color: #fff;
`

const SurveysTable = (props) => {

    const { t } = useTranslation();

    const { filters = null } = props;

    const { getSurveys,
            surveys, 
            setSurveys } = useContext(ContentContext);

    const { panColors } = useContext(ConfigurationContext);
    
    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: t("texts.Titulo"),
            align: 'left',
            sorteable: true,
        },
        {
            id: 'description',
            numeric: false,
            disablePadding: false,
            label: t("texts.Descripcion"),
            align: 'left',
            sorteable: true,
        },
        {
            id: 'date_init',
            numeric: false,
            disablePadding: false,
            label: t("texts.Fecha de inicio"),
            align: 'left',
            sorteable: true,
        },
        {
            id: 'date_finish',
            numeric: false,
            disablePadding: false,
            label: t("texts.Fecha de finalizacion"),
            align: 'left',
            sorteable: true,
        },
        {
            id: 'date_created',
            numeric: false,
            disablePadding: false,
            label: t("texts.Creacion"),
            align: 'left',
            sorteable: true,
        },
        {
            id: '',
            numeric: false,
            disablePadding: false,
            label: t("texts.Acciones"),
            align: 'right',
            sorteable: false,
        }
    ];

    useEffect(() => {
        
        getSurveys(0, 25, filters);

    }, [filters])

    useEffect(() => {
        return () => {
            setSurveys(null);
        }
    }, [])
    
    return (
        <WrapperStyled pancolors={panColors}>
        
        {surveys == null ?
            
            <LoaderComponent />
        :
            surveys.surveys.length ?
            
            <TableSurveys
                headCells={headCells} 
                type={"products"}
                title={t("texts.Productos")} 
                data={surveys.surveys} 
                getData={getSurveys}
                totalRows={surveys.total}
                filters={filters}
            />
            :
            <>{t("texts.No hay registros para mostrar")}</>
        }
        
        </WrapperStyled>
    );
}

export default SurveysTable;