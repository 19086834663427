import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';
import InputCustom from '../elements/InputCustom';
import InputCheckboxCustom from '../elements/InputCheckboxCustom';
import SelectCustom from '../elements/SelectCustom';        
import ButtonTextCustom from '../elements/ButtonTextCustom';
import ModalImage from '../elements/Modals/ModalImage';
import Avatar from '@mui/material/Avatar';

import { ImageIcon,
         TrashIcon } from '../../helpers/icons';

import { ContentContext,
         ConfigurationContext } from '../../context';

import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: column;
    .no_image{
        border: 2px solid transparent;
        &.error{
            border: 2px solid ${props => `rgb(${props.pancolors.red})`};
        }
    }
    .title{
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
        margin-top: 35px;
    }
    .field_title{
        margin-top: 25px;
    }
    .field_question_text{
        margin-top: 25px;
    }
    .box_options{
        display: grid; 
        grid-template-columns: repeat(2, 1fr);
        gap: 30px 50px;
        margin-top: 30px;
        .option{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            margin-bottom: 20px;
            .field_correct{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 10px;
                .inner{
                    display: flex;
                    align-content: center;
                    width: 100%;
                    input{
                        padding: 0;
                        margin: 0;
                        min-height: auto;
                    }
                }
            } 
            .field_label{
                width: calc(100% - 270px);
                margin-right: 20px;
            }
            .field_go_to{
                width: 100%;
                .label{
                    margin-bottom: 5px;
                    min-height: auto;
                }
            }
            .bottom{
                display: flex;
                flex-direction: row;
                width: 100%;
                .image{
                    position: relative;
                    .loader{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0,0,0,0.5);
                        z-index: 1;
                        margin-top: 0!important;
                    }
                    .icon{
                        width: 84px;
                        height: 84px;
                    }
                }
                .answer_data{
                    display: flex;
                    flex-direction: column;
                    width: calc(100% - 165px);
                    margin-left: 15px;
                    justify-content: space-between;
                    .data{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: flex-start;
                        width: 100%;
                        .bt_add_image,
                        .bt_delete_image{
                            height: auto;
                            padding: 0;
                            p{
                                margin: 0;
                            }
                            &.is_loading{
                                p{
                                    color: ${props => `rgb(${props.pancolors.light_gray})`};
                                }
                                .icon svg{
                                    fill: ${props => `rgb(${props.pancolors.light_gray})`};
                                }
                            }
                        }
                        .bt_delete_image{
                            margin-top: 10px;
                        }
                        .aclaration_image{
                            font-size: 12px;
                            line-height: 14px;
                            font-weight: 700;
                            color: rgb(108, 108, 153);
                            margin-top: 10px;
                            &.is_loading{
                                color: ${props => `rgb(${props.pancolors.light_gray})`};
                            }
                        }
                    }
                    .defered_answer{
                        display: flex;
                        flex-direction: row;
                        align-items: flex-end;
                        justify-content: space-between;
                        .field_go_to{
                            width: calc(100% - 170px);
                        }
                        .field_finish{
                            .inner{
                                width: 155px;
                                margin-bottom: 12px;
                                .label_finish{
                                    width: calc(100% - 16px)!important;
                                    margin-bottom: 0;
                                }
                            }
                            .label{
                                width: 100%;
                                label{
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

const MultipleChoiceWithImage = (props) => {

    const [ t ] = useTranslation();

    const { panColors } = useContext(ConfigurationContext);

    const { uploadFile, 
            getByPassImageUploadRoute} = useContext(ContentContext);

    const { questions,
            questionSelected,
            setQuestionSelected,
            response_multiple,
            lang = null,
            setBlockAction } = props;
    
    const [ questionsList, setQuestionsList ] = useState([]);
    const [ croppedImage, setCroppedImage ] = useState(null);
    const [ openModalImage, setOpenModalImage ] = useState(false);
    const [ uploadRoute, setUploadRoute ] = useState(null);
    const [ answerOrder, setAnswerOrder ] = useState(null);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};
            
    const handleFormElementChange = (name, value, index) => {
        
        let question_selected_update = {...questionSelected};
        let secondary_actions_update = {...question_selected_update.translations[lang].secondary_actions[index]};

        switch (name) {
            case "label":
                secondary_actions_update.label.value = value;
                break;
            case "correct":
                if(!response_multiple){
                    let corrects = question_selected_update.translations[lang].secondary_actions.map((q, i) => {
                        if(i !== index){
                            q.correct.value = false;
                        }else{
                            q.correct.value = true;
                        }
                        return q;
                    })
                    question_selected_update.translations[lang].secondary_actions = corrects;
                }else{
                    secondary_actions_update.correct.value = !secondary_actions_update.correct.value;
                }
                break;
            case "go_to":
                secondary_actions_update.go_to.value = value;
                break;
            case "title":
                question_selected_update.translations[lang].title.value = value;
                break;
            case "finish":
                secondary_actions_update.finish.value = !secondary_actions_update.finish.value;
                break;
            default:
                break;
        }

        question_selected_update.translations[lang].secondary_actions[index] = secondary_actions_update;
        setQuestionSelected(question_selected_update);
    }

    const handleImageAnswer = (item, index) => {

        setAnswerOrder(index);
        setOpenModalImage(true);

    }

    const handleUploadFile = (imagen) => {
        
        let cropped_image = imagen;
        let upload_route = null;

        const payload = { name: "image-"+uuid()+".jpeg",
                          file: cropped_image };
            
        const uploadRoute = getByPassImageUploadRoute(payload);

        uploadRoute.then((res) => {
            
            upload_route = res;
            const uploaded = uploadFile(cropped_image, upload_route);

            uploaded.then(uploaded_res => {
                
                setBlockAction(false);
                const answer_update = {...questionSelected};
                answer_update.translations[lang].secondary_actions[answerOrder].media.loading = false;
                answer_update.translations[lang].secondary_actions[answerOrder].media.value = uploaded_res;
                setQuestionSelected(answer_update);
                setCroppedImage(null);

            })
        })
    }

    const handleDeleteAnswerImage = (item, index) => {

        const answer_update = {...questionSelected};
        answer_update.translations[lang].secondary_actions[index].media.value = "";
        setQuestionSelected(answer_update);

    }

    useEffect(() => {
      
        if(questions){
            const questions_updates = questions.map((question, index) => {
                return {_id: question._id.value, name: (index + 1)}
            })
            setQuestionsList(questions_updates);
        }
    
      return () => {}
    }, [questions])
    
    useEffect(() => {
        
        if(croppedImage){
            setBlockAction(true);
            handleUploadFile(croppedImage);
            const answer_update = {...questionSelected};
            answer_update.translations[lang].secondary_actions[answerOrder].media.loading = true;
            answer_update.translations[lang].secondary_actions[answerOrder].media.value = croppedImage;
            setQuestionSelected(answer_update);
        }
    
      return () => {}
    }, [croppedImage])

    if(!Object.keys(questionSelected).length){
        return null;
    }

    return (
        <WrapperStyled pancolors={panColors}>

            {openModalImage &&
                <ModalImage 
                    openModalImage={openModalImage} 
                    setOpenModalImage={setOpenModalImage} 
                    croppedImage={croppedImage}
                    setCroppedImage={setCroppedImage}
                    uploadRoute={uploadRoute}
                    setUploadRoute={setUploadRoute}
                    scope={"cover_image"}
                    maxSize={""}
                />
            }

            <InputCustom 
                name={questionSelected.translations[lang].title.ref}
                label={t("texts."+questionSelected.translations[lang].title.label)}
                placeholder={t("texts."+questionSelected.translations[lang].title.placeholder)}
                onChange={handleFormElementChange}
                onKeyDown={null}
                className={`field field_${questionSelected.translations[lang].title.ref}${questionSelected.translations[lang].title.error ? " error" : ""}`}
                type="text"
                value={questionSelected.translations[lang].title.value}
                help={questionSelected.translations[lang].title.help}
                isRequired={questionSelected.translations[lang].title.required}
                styles={ formStyle }
                label_icon={null}
                tooltip_content={null}
                haveError={questionSelected.translations[lang].title.error}
            />
            
            <div className="title">{t("texts.Seleccionar las opciones correctas")}: *</div>

            <div className="box_options">
                
                {questionSelected.translations[lang]?.secondary_actions?.map((item, index) => {
                    return(
                        <div key={"op-"+index} className="option">
                            <InputCheckboxCustom
                                name={item?.correct?.ref}
                                label={t("texts."+item?.correct?.label)}
                                placeholder={item?.correct?.placeholder ? t("texts."+item?.correct?.placeholder) : ""}
                                onChange={handleFormElementChange}
                                className={`field field_${item?.correct?.ref}${item?.correct?.error ? " error" : ""}`}
                                value={item?.correct?.value}
                                help={item?.correct?.help}
                                isRequired={item?.correct?.required}
                                styles={ formStyle }
                                label_icon={null}
                                tooltip_content={null}
                                haveError={item?.correct?.error}
                                index={index}
                            />
                            
                            <div className="bottom">
                                <div className="image">
                                    { item.media.loading ? <div className="loader"><LoaderComponent /></div> : "" }
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={item?.media?.value ? item?.media?.value : ""}
                                        sx={{ width: 150, height: 150 }}
                                        variant="square"
                                        className={`no_image${item.media.error ? " error" : ""}`}
                                    >
                                        <ImageIcon className="icon" />
                                    </Avatar>
                                </div>
                                <div className="answer_data">
                                    <div className="data">
                                        {!item?.media?.value &&
                                        <ButtonTextCustom 
                                            className={`bt_add_image${item.media.loading ? " is_loading" : ""}`}
                                            text={t("texts.+ Agregar/Modificar imagen")}
                                            onClick={() => { !item.media.loading && handleImageAnswer(item, index); } }
                                            icon={null}
                                            background={{ normal: "", hover: "" }}
                                            border={{ normal: "", hover: "" }}
                                            textcolor={{ normal: panColors.primary, hover: panColors.primary }}
                                        />
                                        }
                                        {item?.media?.value &&
                                            <ButtonTextCustom 
                                                className={`bt_delete_image${item.media.loading ? " is_loading" : ""}`}
                                                text={t("texts.Borrar")}
                                                onClick={() => { !item.media.loading && handleDeleteAnswerImage(item, index) } }

                                                icon={<TrashIcon />}
                                                background={{ normal: "", hover: "" }}
                                                border={{ normal: "", hover: "" }}
                                                textcolor={{ normal: panColors.red, hover: panColors.red }}
                                            />
                                        }
                                    </div>
                                    {questions &&
                                        <div className='defered_answer'>
                                            <SelectCustom 
                                                name={item?.go_to?.ref}
                                                label={item?.go_to?.label}
                                                placeholder={item?.go_to?.placeholder}
                                                onChange={handleFormElementChange}
                                                className={`field field_${item?.go_to?.ref}${item?.go_to?.error ? " error" : ""}`}
                                                type="text"
                                                value={item?.go_to?.value}
                                                help={item?.go_to?.help}
                                                isRequired={item?.go_to?.required}
                                                styles={ formStyle }
                                                label_icon={null}
                                                tooltip_content={null}
                                                haveError={item?.go_to?.error}
                                                data={questionsList}
                                                index={index}
                                            />
                                            <InputCheckboxCustom
                                                name={item?.finish?.ref}
                                                label={item?.finish?.label}
                                                placeholder={item?.finish?.placeholder}
                                                onChange={handleFormElementChange}
                                                className={`field field_${item?.finish?.ref}${item?.finish?.error ? " error" : ""}`}
                                                labelPosition={"right"}
                                                value={item?.finish?.value}
                                                help={item?.finish?.help}
                                                isRequired={item?.finish?.required}
                                                styles={ formStyle }
                                                label_icon={null}
                                                tooltip_content={null}
                                                haveError={item?.finish?.error}
                                                index={index}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}

                
                
            </div>
            
        </WrapperStyled>
    );
}

export default React.memo(MultipleChoiceWithImage);