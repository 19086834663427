import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ConfigurationContext } from '../../context';
import LabelCustom from '../elements/LabelCustom';
import ValidateID from '../elements/ValidateID';

const WrapperStyled = styled.div`
    >.label_custom{
        margin-bottom: 5px;
    }
    >.inner{
        position: relative;
        >.icon{
            top: 11px;
            left: 10px;
            font-size: 23px;
            position: absolute;
            color: #645FF9;
        }
        textarea{
            position: relative;
            background-color: ${props => props.styles.background_color};
            border: 1px solid ${props => props.styles.border_color};
            border-radius: 6px;
            padding-left: ${props => props.icon ? "40px" : "10px" };
            padding-right: ${props => props.icon ? "40px" : "10px" };
            padding-top: 11px;
            padding-bottom: 11px;
            min-height: 44px;
            color: #484D63;
            font-size: 16px;
            width: 100%;
            box-sizing: border-box;
            
            :focus{
                outline: none !important;
                border-color: ${props => `rgb(${props.panColors.dots})`};
            }
        }
    }
    >.help{
        font-size: 14px;
        margin-top: 5px;
        font-weight: 700;
        color: ${props => `rgb(${props.panColors.dark_gray})`};
        &.error{
            color: ${props => `rgb(${props.panColors.red})`};
        }
    }
    &.error{
        textarea{
            border: 2px solid ${props => `rgb(${props.panColors.red})`};
            :focus{
                outline: none !important;
                border-color: ${props => `rgb(${props.panColors.red})`};
            }
        }
    }
`

const TextareaCustom = (props) => {

    const { panColors } = useContext(ConfigurationContext);

    const { name,
            label,
            onChange,
            onKeyDown,
            onKeyUp,
            className,
            value,
            help,
            placeholder,
            icon = null,
            label_icon = null,
            styles = null,
            disabled = false,
            isRequired = false,
            tooltip_content = null,
            haveError = null,
            validateStatus = null,
            setValidateStatus = null } = props;
    
    return (
        <WrapperStyled 
            panColors={panColors}
            styles={styles}
            className={className} 
            icon={icon ? true : false}
        >
            
            { label ? 
                <LabelCustom 
                    name={name}
                    label={label}
                    styles={styles}
                    label_icon={label_icon}
                    isRequired={isRequired}
                    tooltip_content={tooltip_content}
                />
            : "" }

            <div className="inner">
                
                {name === "user_login" && <ValidateID status={validateStatus} setStatus={setValidateStatus} />}
                
                <textarea 
                    name={name} 
                    id={name} 
                    onChange={(e) => onChange ? onChange(name, e.target.value) : null}
                    onKeyDown={(e) => onKeyDown ? onKeyDown(e.key, name, e.target.value) : null}
                    onKeyUp={(e) => onKeyUp ? onKeyUp(name, e.target.value) : null}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value ? value : ""}
                />

                {icon ? 
                <div className="icon">
                    {icon}
                </div>
                : ""
                }
            </div>

            { help ? <div className={`help${haveError ? " error" : ""}`}>{help}</div> : "" }

        </WrapperStyled>

    )

}

export default TextareaCustom;