import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import { FaRegComment } from "react-icons/fa";

import { DateTime } from "luxon";

import { TrashIcon,
         ModeCommentIcon,
         FavoriteBorderIcon } from '../../../helpers/icons';

import { BiTrash, BiPencil } from "react-icons/bi";
import { HiEye } from "react-icons/hi2";

import { ContentContext,
         ConfigurationContext } from '../../../context';

import { useNavigate, useLocation } from 'react-router-dom';
import DeleteElement from "../Dialogs/DeleteElement";
import CommentsResponse from '../../../components/elements/Dialogs/CommentsResponse';
import ButtonTextCustom from '../../../components/elements/ButtonTextCustom';
import { useTranslation } from 'react-i18next';

dayjs.extend(utc);
dayjs.extend(timezone);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {

  const { onSelectAllClick, 
          order, 
          orderBy, 
          numSelected, 
          rowCount, 
          onRequestSort, 
          headCells } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            disabled={true}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sorteable ? 
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >

              { headCell.label === "Comments" && <ModeCommentIcon /> }
              { headCell.label === "Likes" && <FavoriteBorderIcon /> }
              { headCell.label !== "Likes" && headCell.label !== "Comments" && headCell.label }

              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
            :
            headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


const WrapperStyled = styled.div`

  color: ${props => `rgb(${props.pancolors.black_overwhite})`};

  .description_short{
    max-width: 350px;
  }
  .title{
    max-width: 150px;
  }
  .inner_avatar{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
  }
  .icon{
      fill: #FFFFFF!important;
  }
  .MuiToolbar-root{
      padding-left: 15px;
  }
  .MuiTableRow-root{
      &.Mui-selected{
          background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
          :hover{
              background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
          }
      }
      &.MuiTableRow-hover{
          :hover{
              background-color: ${props => `rgba(${props.pancolors.dots}, 0.03)`};
          }
      }
  }

  .MuiTableCell-body{
    color: ${props => `rgb(${props.pancolors.black_overwhite})`}!important;
  }

  .bt_bulk_delete{

  }
  .bt_bulk_activate{
      margin-left: 10px;
  }
  .bt_bulk_desactivate{
      margin-left: 10px;
  }
  .Mui-checked{
      color: ${props => `rgba(${props.pancolors.dots}, 1)`}!important;
  }
  .MuiCheckbox-indeterminate{
      color: ${props => `rgba(${props.pancolors.dots}, 1)`}!important;
  }
  .comment_cell{

  }
  .create_cell{
    width: 90px;
  }
  .user_name{
    width: 120px;
  }
  .code_cell{
    width: 120px;
  }
  .user_name_cell{
    width: 170px;
  }
  .title_post_cell{
    width: 250px;
  }
  .actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .bt_delete_single,
    .bt_edit_single,
    .bt_view_single{
      display: flex;
      font-size: 20px;
      color: ${props => `rgb(${props.pancolors.red})`};
      cursor: pointer;
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
    .bt_edit_single,
    .bt_view_single{
      margin-right: 10px;
      color: ${props => `rgb(${props.pancolors.dots})`};
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
  }
  .create_publish{
    .create{

    }
    .publish{
      font-weight: 600;
    }
  }
  .comments_counter{
    width: 60px;
    padding-left: 25px;
  }
  .likes_counter{
    width: 60px;
    padding-left: 25px;
  }
`

const ToolbarStyled = styled(Toolbar)`
    
  &.MuiToolbar-root{
    background-color: ${props => `${props.numselected ? "rgba("+props.pancolors.dots+", 0.03)" : "transparent"}` }!important;
  }

`

function EnhancedTableToolbar(props) {

    const { t } = useTranslation();

    const { numSelected,
            title,
            panColors,
            selected,
            setSelected,
            setRows,
            getData,
            page,
            rowsPerPage,
            filters } = props;

    const [ isDeleted, setIsDeleted ] = useState(false);
    const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
    const [ titleDeleteText, setTitleDeleteText ] = useState("");
    const [ elementToDelete, setElementToDelete ] = useState("");
    
    const handleDeleteRows = () => {

      const id_list = selected.map((id, index) => {
        return {_id: id};
      })
      setElementToDelete(JSON.stringify(id_list));
      setTitleDeleteText(t("texts.Confirmar que desea Eliminar", {quantity: selected.length, item: selected.length > 1 ? t("texts.Comentarios").toLowerCase() : t("texts.Comentario").toLowerCase()}));
      setDeleteElementOpen(true);
    }

    useEffect(() => {
      
      if(isDeleted){
        setRows([]);
        getData(page, rowsPerPage, filters);
        setElementToDelete(null);
        setTitleDeleteText('');
        setDeleteElementOpen(false);
        setIsDeleted(false);
        setSelected([]);
      }
    
      return () => {}
    }, [isDeleted])
    

  return (
    <ToolbarStyled
        pancolors={panColors}
        numselected={numSelected}
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      {numSelected > 0 ? (
        <></>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          { title }
        </Typography>
      )}
      
      <DeleteElement 
        open={deleteElementOpen} 
        setOpen={setDeleteElementOpen} 
        title={titleDeleteText}
        setTitle={setTitleDeleteText}
        id={elementToDelete}
        scope="comments"
        isDeleted={isDeleted}
        setIsDeleted={setIsDeleted}
      />

      {numSelected > 0 ? (
        <>

        <ButtonTextCustom 
            className="bt_bulk_delete"
            text={t("texts.Eliminar")}
            onClick={() => handleDeleteRows()}

            icon={<TrashIcon />}
            background={{ normal: "", hover: panColors.red }}
            border={{ normal: panColors.primary, hover: panColors.primary }}
            textcolor={{ normal: panColors.red, hover: panColors.red }}
        />
        </>
      ) : (
        <></>
        
      )}
    </ToolbarStyled>
  );
}


const DataTable = (props) => {

  const { t } = useTranslation();

    const { headCells,
            title,
            data,
            getData,
            totalRows,
            filters } = props;

  const navigate = useNavigate();

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = React.useState([]);
  const [ deleteElementOpen, setDeleteElementOpen ] = useState(false);
  const [ commentElementOpen, setCommentElementOpen ] = useState(false);
  const [ titleDeleteText, setTitleDeleteText ] = useState("");
  const [ elementToDelete, setElementToDelete ] = useState("");
  const [ isDeleted, setIsDeleted ] = useState(false);
  const [ selectedLang, setSelectedLang ] = useState(null);
  const [ rowToResponse, setRowToResponse ] = useState(null);
  

  const { colors,
          panColors } = useContext(ConfigurationContext);

  const { setCourse, 
          basicData,
          setIdCourseSelected } = useContext(ContentContext);

  useEffect(() => {
      if(basicData){
          setSelectedLang(basicData.language[0].prefix);
      }
    return () => {}
  }, [basicData])

    useEffect(() => {
        
      if(data === null){
        getData(0, rowsPerPage, filters);
      }
      
      if(data && data.length){
        setRows(data);
      }
      return () => {}

    }, [data])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    getData(newPage, rowsPerPage, filters);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    getData(0, parseInt(event.target.value, 10), filters);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmitDelete = () => {
    alert("Delete _id: " + elementToDelete);
  }

  const handleDelete = (id, fullname) => {

    //setElementToDelete(id);
    setElementToDelete(JSON.stringify([{_id: id}]));
    setTitleDeleteText(t("texts.Confirmar Eliminar el comentario", {fullname: fullname}));
    setDeleteElementOpen(true);

  }

  const handleComment = (row) => {

    setRowToResponse(row);
    setCommentElementOpen(true);

  }

  useEffect(() => {
    
    if(isDeleted){
      setRows([]);
      getData(page, rowsPerPage, filters);
      setElementToDelete(null);
      setTitleDeleteText('');
      setDeleteElementOpen(false);
      setIsDeleted(false);
    }
  
    return () => {}
  }, [isDeleted])

  const isSelected = (name) => selected.indexOf(name) !== -1;
  
  if(data === null){
    return null;
  }

  return (
    <WrapperStyled pancolors={panColors}>

        {rowToResponse &&
        <CommentsResponse 
          open={commentElementOpen} 
          setOpen={setCommentElementOpen} 
          row={rowToResponse}
          setRow={setRowToResponse}
          //id={rowToResponse?._id}
        />}

        <DeleteElement 
          open={deleteElementOpen} 
          setOpen={setDeleteElementOpen} 
          title={titleDeleteText}
          setTitle={setTitleDeleteText}
          id={elementToDelete}
          scope="comments"
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
        />

        <EnhancedTableToolbar 
            numSelected={selected.length} 
            selected={selected}
            setSelected={setSelected}
            title={title}
            colors={colors}
            panColors={panColors}
            setRows={setRows}
            getData={getData}
            page={page}
            rowsPerPage={rowsPerPage}
            filters={filters}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {

                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  
                    return (
                        <TableRow
                            hover
                            //onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row._id}
                            selected={isItemSelected}
                        >

                            <TableCell padding="checkbox">
                                <Checkbox
                                    onClick={(event) => handleClick(event, row._id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    disabled={false}
                                    inputProps={{ 'aria-labelledby': labelId, }}
                                />
                            </TableCell>  
                            
                            <TableCell className='create_cell' align="left">{row.created_date ? dayjs(row.created_date).tz(dayjs.tz.guess()).format('HH:mm:ss - DD/MM/YYYY') : "- - -"}</TableCell>
                            <TableCell className='user_name' align="left">{row.user_name ? row.user_name : "- - -"}</TableCell>
                            <TableCell className='code_cell' align="left">{row.code ? row.code : "- - -"}</TableCell>
                            <TableCell className='user_name_cell' align="left">{row.user_login ? row.user_login : "- - -"}</TableCell>
                            <TableCell className='title_post_cell' align="left">{row.posts_title ? row.posts_title : "- - -"}</TableCell>
                            <TableCell className='comment_cell' align="left">{row.comment ? row.comment : "- - -"}</TableCell>
                            
                            <TableCell align="center">
                                <div className="actions">
                                    {/* <Tooltip title={"Ver más"} arrow placement="top" enterTouchDelay={0}>
                                        <div className='bt_view_single' onClick={() => navigate(`/survey/${row._id}/view`)}>
                                            <HiEye />
                                        </div>
                                    </Tooltip> */}
                                    <Tooltip title={t("texts.Editar")} arrow placement="top" enterTouchDelay={0}>
                                        <div className='bt_edit_single' onClick={() => handleComment(row)}>
                                            <FaRegComment />
                                        </div>
                                    </Tooltip>
                                    <Tooltip title={t("texts.Eliminar")} arrow placement="top" enterTouchDelay={0}>
                                        <div className='bt_delete_single' onClick={() => handleDelete(row._id, row.name)}>
                                            <BiTrash />
                                        </div>
                                    </Tooltip>
                                </div>
                            </TableCell>

                        </TableRow>
                    );
                  
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      
    </WrapperStyled>
  );
}

export default DataTable;