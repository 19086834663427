import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(Backend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    
    backend: {
      loadPath: 'https://resources-one.s3.us-east-2.amazonaws.com/one_sp/locales/{{lng}}/{{ns}}.json', // Ruta donde se alojan los archivos de traducción
    },
    
    debug: true,
    fallbackLng: 'en',
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export default i18n;
