import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';

import { ContentContext,
         ConfigurationContext } from '../../context';

import TableStores from './../elements/DataTable/TableStores';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
    background-color: #fff;
  .actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    .bt_delete_single,
    .bt_edit_single,
    .bt_view_single{
      display: flex;
      font-size: 20px;
      color: ${props => `rgb(${props.pancolors.red})`};
      cursor: pointer;
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
    .bt_edit_single,
    .bt_view_single{
      margin-right: 10px;
      color: ${props => `rgb(${props.pancolors.dots})`};
      :hover{
        color: ${props => `rgb(${props.pancolors.black_overwhite})`};
      }
    }
  }
`

const StoresTable = (props) => {

    const { t } = useTranslation();

    const { filters = null } = props;

    const { getStores,
            basicData,
            stores, 
            setStores } = useContext(ContentContext);

    const { panColors,
            clientData } = useContext(ConfigurationContext);
            
    const [ headCells, 
            setHeadCells ] = useState([]);


    useEffect(() => {
        
        if(basicData){
  
          const _headCells = [];
          
          if((basicData.settings.store.section_list_columns.filter(col => col.id === 'name')).length){
                _headCells.push({ id: 'name',
                                  numeric: false,
                                  disablePadding: false,
                                  label: (basicData.settings.store.section_list_columns.filter(col => col.id === 'name'))[0].label,
                                  align: 'left', });
          }

          if((basicData.settings.store.section_list_columns.filter(col => col.id === 'code')).length){
                _headCells.push({ id: 'code',
                                  numeric: false,
                                  disablePadding: false,
                                  label: (basicData.settings.store.section_list_columns.filter(col => col.id === 'code'))[0].label,
                                  align: 'left', });
          }

          if((basicData.settings.store.section_list_columns.filter(col => col.id === 'region')).length){
                _headCells.push({ id: 'region',
                                  numeric: false,
                                  disablePadding: false,
                                  label: (basicData.settings.store.section_list_columns.filter(col => col.id === 'region'))[0].label,
                                  align: 'left', });
          }

          if((basicData.settings.store.section_list_columns.filter(col => col.id === 'area')).length){
                _headCells.push({ id: 'area',
                                  numeric: false,
                                  disablePadding: false,
                                  label: (basicData.settings.store.section_list_columns.filter(col => col.id === 'area'))[0].label,
                                  align: 'left', });
          }

          if((basicData.settings.store.section_list_columns.filter(col => col.id === 'cluster')).length){
                _headCells.push({ id: 'cluster',
                                  numeric: false,
                                  disablePadding: false,
                                  label: (basicData.settings.store.section_list_columns.filter(col => col.id === 'cluster'))[0].label,
                                  align: 'left', });
          }

          if((basicData.settings.store.section_list_columns.filter(col => col.id === 'address')).length){
                _headCells.push({ id: 'address',
                                  numeric: false,
                                  disablePadding: false,
                                  label: (basicData.settings.store.section_list_columns.filter(col => col.id === 'address'))[0].label,
                                  align: 'left', });
          }

          if((basicData.settings.store.section_list_columns.filter(col => col.id === 'format')).length){
                _headCells.push({ id: 'format',
                                  numeric: false,
                                  disablePadding: false,
                                  label: (basicData.settings.store.section_list_columns.filter(col => col.id === 'format'))[0].label,
                                  align: 'left', });
          }

          _headCells.push({ id: '',
                            numeric: false,
                            disablePadding: false,
                            label: 'Acciones',
                            align: 'right',
                            sorteable: false, });

          setHeadCells(_headCells);
  
        }
        return () => {}
      }, [basicData])


    useEffect(() => {
        
        setStores(null);
        getStores(0, 25, filters);

    }, [filters])

    useEffect(() => {
        return () => {
            setStores(null);
        }
    }, [])
    
    return (
        <Wrapper pancolors={panColors}>
        
        
        {stores == null || stores === undefined ?

            <LoaderComponent />

        :
            stores.stores.length ?
            <TableStores 
                headCells={headCells} 
                type={"stores"}
                title={clientData.name_clients !== "one_sp" ? t("texts.Tiendas") : t("texts.Estaciones")} 
                data={stores.stores} 
                getData={getStores}
                totalRows={stores.total}
                filters={filters}
                settings={basicData.settings.store}
            />
            :
            <>{t("texts.No hay registros para mostrar")}</>
        }
        
        </Wrapper>
    );
}

export default StoresTable;