import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';

import ButtonCustom from '../../../../components/elements/ButtonCustom';
import IconCustom from '../../../../components/elements/IconCustom';
import SelectCustom from '../../../../components/elements/SelectCustom';
import TextareaCustom from '../../../../components/elements/TextareaCustom';
import LoaderComponent from '../../../../components/elements/LoaderComponent';
import { useNavigate, useParams } from 'react-router-dom';

import { ContentContext,
         ConfigurationContext } from '../../../../context';

import { UserIcon,
         StoreIcon,
         OtherAdmissionIcon,
         HeadlineIcon,
         DocumentScannerIcon,
         CalendarMonthIcon,
         RuleIcon } from '../../../../helpers/icons';
import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`

    width: 100%;
    .loader{
        margin-top: 30px;
        width: 100%;
    }

    /* .actions{
        display: flex;
        flex-direction: row;
        margin-top: 25px;
        .bt_cancel{
            margin-left: 10px;
        }
    } */

    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 25px;

        .bt_submit{

        }
        .bt_program_activation{
            margin-left: 10px;
        }
        .bt_cancel{
            margin-left: 10px;
        }
        >.loader{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            margin: 0;
            border-radius: 4px;
            background-color: ${props => `rgb(${props.pancolors.primary})`};
        }
    }

    .box_container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0;
        &.box_moderation_form{
            border-bottom: 1px solid ${props => `rgb(${props.pancolors.middle_gray})`};
        }
        &.box_moderation_data{
            margin-top: 25px;
        }
        &.line{
            border-bottom: 1px solid rgb(186, 186, 194);
            padding-bottom: 25px;
        }
        .field_moderate{
            width: 100%;
            margin-bottom: 30px;
            select{
                width: 100%;
            }
        }
        .field_comment{
            width: 100%;
            margin-bottom: 10px;
            textarea{
                height: 100px;
            }
        }
        .moderation_data{
            display: flex;
            flex-direction: row;
            width: 100%;
            .data{
                /* display: grid; 
                grid-template-columns: repeat(2, 1fr);
                gap: 20px 10px; */
                display: flex;
                flex-direction: column;
                width: calc(100% - 630px);
                align-items: flex-start;
                
                .title{
                    font-size: 18px;
                    line-height: 23px;
                    font-weight: 600;
                    margin-top: 15px;
                    margin-bottom: 20px;
                }
                .item{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 15px;
                    .icon{
                        margin-right: 10px;
                    }
                    .value{
                        font-weight: 300;
                        .highlighted{
                            font-weight: 600;
                        }
                    }
                    &.item-labels{
                        align-items: flex-start;
                    }
                }
            }
            .media{
                width: 400px;
                margin-left: 30px;
                .img{
                    display: flex;
                    border: 1px solid ${props => `rgb(${props.pancolors.dots})`};
                    width: 100%;
                    img{
                        float: left;
                        width: 100%;
                    }
                }
                //background-color: pink;
            }
        }
    }

`

const AuditsContainer = () => {

    const { t } = useTranslation();

    const { id_audit } = useParams();
    const navigate = useNavigate();

    const { panColors } = useContext(ConfigurationContext);
    const { audit, 
            setAudit,
            getAudit,
            saveModeration } = useContext(ContentContext);
    
    const [ oldStatus, setOldStatus ] = useState("");
    const [ loadingForm, setLoadingForm ] = useState(false);

    const [ moderate, setModerate ] = useState({ value: "", 
                                                 required: true, 
                                                 visibility: true, 
                                                 disabled: false, 
                                                 ref: "moderate", 
                                                 label: t("texts.Moderacion"), 
                                                 placeholder: null,
                                                 help: "",
                                                 error: false });

    const [ comment, setComment ] = useState({ value: "", 
                                               required: false, 
                                               visibility: true, 
                                               disabled: false, 
                                               ref: "comment", 
                                               label: t("texts.Comentario"), 
                                               placeholder: null,
                                               help: "",
                                               error: false });

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    const handleFormElementChange = (name, value) => {
        //console.log(name, value)
        switch (name) {
            case "moderate":
                const moderate_update = {...moderate};
                moderate_update.value = value;
                setModerate(moderate_update);
                break;
            case "comment":
                const comment_update = {...comment};
                comment_update.value = value;
                setComment(comment_update);
                break;
            default:
                break;
        }
    }

    const handleSaveModaration = () => {
        
        setLoadingForm(true);
        const payload = { new_status: parseInt(moderate.value), 
                          comment: comment.value,
                          id_form: id_audit,
                          old_status: oldStatus
        }

        const response = saveModeration(payload);
        response.then(res => {
            setLoadingForm(false);
            handleCancel();
        })
    }

    const handleCancel = () => {

        navigate(`/stores/campaigns`);

    }

    useEffect(() => {
      
        getAudit({audit_id: id_audit});
    
      return () => {

        setAudit(null)

      }
    }, [])

    useEffect(() => {
      
        if(audit){
            if("moderations" in audit[0]){
                //console.log("- - - - - - - - - - -")
                if(audit[0].moderations[0].moderate_comment){
                    //console.log("SI", audit[0].moderations[0].moderate_comment)
                }else{
                    //console.log("NO", audit[0].moderations[0].moderate_comment)
                }
                //console.log("- - - - - - - - - - -")
            }

            if(audit[0]?.status){
                const moderate_update = {...moderate};
                moderate_update.value = audit[0]?.status.toString();
                setModerate(moderate_update);
                setOldStatus(audit[0].status);
            }
        }
    
      return () => {}
    }, [audit])
    
    
    if(!audit){
        return (
            <div className='loader'>
                <LoaderComponent size={40} color={panColors.dots} />
            </div>
        );
    }

    return (
        <WrapperStyled
            pancolors={panColors}
        >

            <div className='box_container box_moderation_form'>
                <div className='field_moderation'>
                    <SelectCustom 
                        name={moderate.ref}
                        label={moderate.label}
                        placeholder={moderate.placeholder}
                        onChange={handleFormElementChange}
                        className={`field field_${moderate.ref} ${moderate.error ? " error" : ""}`}
                        type="text"
                        value={moderate.value}
                        help={null}
                        isRequired={false}
                        styles={ formStyle }
                        label_icon={<RuleIcon />}
                        tooltip_content={null}
                        haveError={moderate.error}
                        data={[{name: t("texts.Pendiente"), _id: 0}, {name: t("texts.En revision"), _id: 2}, {name: t("texts.Aprobado"), _id: 1}, {name: t("texts.Rechazado"), _id: 3}]}
                    />
                </div>
                
                <div className='field_comment'>
                    <TextareaCustom 
                        name={comment.ref}
                        label={comment.label}
                        placeholder={comment.placeholder}
                        onChange={handleFormElementChange}
                        onKeyDown={null}
                        className={`field field_${comment.ref}${comment.error ? " error" : ""}`}
                        type="text"
                        value={comment.value}
                        help={""}
                        isRequired={comment.required}
                        styles={ formStyle }
                        label_icon={<HeadlineIcon />}
                        tooltip_content={comment.tooltip}
                        haveError={comment.error}
                    />
                </div>

                {"moderations" in audit[0] ?
                    audit[0].moderations[0].moderate_comment ?
                    <div className='moderation_data'>
                        <div className='data'>
                            <div className='item'>
                                <div className='icon'><IconCustom icon={<HeadlineIcon />} /></div>
                                <div className='value'><span className='highlighted'>{t("texts.Comentarios")}:</span> { audit ? ("moderations" in audit[0]) ? audit[0].moderations[0].moderate_comment : "---" : "---" }</div>
                            </div>  
                        </div>  
                    </div>
                    
                    :  "" : ""
                    
                }

            </div>

            {/* <div className='box_container'>
                <div className='field_comment'>

                    <TextareaCustom 
                        name={comment.ref}
                        label={comment.label}
                        placeholder={comment.placeholder}
                        onChange={handleFormElementChange}
                        onKeyDown={null}
                        className={`field field_${comment.ref}${comment.error ? " error" : ""}`}
                        type="text"
                        value={comment.value}
                        help={""}
                        isRequired={comment.required}
                        styles={ formStyle }
                        label_icon={<HeadlineIcon />}
                        tooltip_content={comment.tooltip}
                        haveError={comment.error}
                    />
                    
                </div>
            </div> */}

            {audit[0]?.answers?.map((answer, index) => {
                return(
                    <div className={`box_container ${audit[0].answers.length > 1 ? "line" : ""} box_moderation_data`}>
                        <div className='moderation_data'>
                            <div className='data'>
                                <div className='title'>{t("texts.Datos del formulario")}</div>
                                
                                <div className='item'>
                                    <div className='icon'><IconCustom icon={<CalendarMonthIcon />} /></div>
                                    <div className='value'><span className='highlighted'>{t("texts.Fecha y horario de Publicacion")}:</span> { audit[0]?.date_init ? dayjs(audit[0].date_init).format("DD/MM/YYYY hh:mm:ss") : "---" }</div>
                                </div>
                                <div className='item'>
                                    <div className='icon'><IconCustom icon={<HeadlineIcon />} /></div>
                                    <div className='value'><span className='highlighted'>{t("texts.Pregunta")}:</span> { answer.question ? answer.question : "---" }</div>
                                </div>
                                <div className='item'>
                                    <div className='icon'><IconCustom icon={<HeadlineIcon />} /></div>
                                    <div className='value'><span className='highlighted'>{t("texts.Formulario")}:</span> { audit[0]?.name_activity ? audit[0].name_activity : "---" }</div>
                                </div>
                                <div className='item'>
                                    <div className='icon'><IconCustom icon={<UserIcon />} /></div>
                                    <div className='value'><span className='highlighted'>{t("texts.Nombre del Usuario")}:</span> { audit[0]?.name_user ? audit[0].name_user : "---" }</div>
                                </div>
                                <div className='item'>
                                    <div className='icon'><IconCustom icon={<UserIcon />} /></div>
                                    <div className='value'><span className='highlighted'>{t("texts.ID usuario")}:</span> { audit[0]?.user_login }</div>
                                </div>
                                
                                <div className='item'>
                                    <div className='icon'><IconCustom icon={<StoreIcon />} /></div>
                                    <div className='value'><span className='highlighted'>{t("texts.Codigo de la tienda")}:</span> { audit[0]?.code }</div>
                                </div>
                                <div className='item'>
                                    <div className='icon'><IconCustom icon={<StoreIcon />} /></div>
                                    <div className='value'><span className='highlighted'>{t("texts.Nombre de la tienda")}:</span> { audit[0]?.name_store ? audit[0].name_store : "---" }</div>
                                </div>
                            </div>
                            <div className='media'>
                                { audit && "answers" in audit[0] && audit[0].answers.length && "questionImage" in audit[0].answers[0] ?
                                    <div className='img'>
                                        <img src={audit[0].answers[0].questionImage} />
                                    </div>
                                : "" }
                            </div>
                        </div>
                        
                    </div>
                )
            })}

            <div className='actions'>
                {!loadingForm ?
                    <ButtonCustom 
                        className="bt_save"
                        text={t("texts.Guardar")}
                        onClick={handleSaveModaration}
                        style_type="normal"
                        
                        icon={null}
                        background={{ normal: panColors.primary, hover: panColors.white }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                    />
                    :
                    <div className='loader'>
                        <LoaderComponent size={25} color={panColors.white} />
                    </div>
                }
                <ButtonCustom 
                    className="bt_cancel"
                    text={t("texts.Cancelar")}
                    onClick={handleCancel}
                    style_type="invert"

                    icon={null}
                    background={{ normal: panColors.primary, hover: panColors.white }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.white, hover: panColors.primary }}
                />
            </div>
        </WrapperStyled>
    );
};

export default AuditsContainer;