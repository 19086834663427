import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import Form from '../../../components/elements/Users/Form';

import { ConfigurationContext } from '../../../context';

import SuccessForm from '../../../components/elements/SuccessForm';
import ModalImageProfile from '../../../components/elements/Modals/ModalImageProfile';
import ScheduleCreate from '../../../components/elements/Dialogs/ScheduleCreate';
import successImage from '../../../assets/images/img_success1.svg';

import { useTranslation } from 'react-i18next';
import 'react-image-crop/dist/ReactCrop.css';

const WrapperStyled = styled.div`
    width: 100%;
`

const CreateContainer = (props) => {

    const { t } = useTranslation();

    const [ scheduleCreateOpen, setScheduleCreateOpen ] = useState(false);
    const [ openModalImageProfile, setOpenModalImageProfile ] = useState(false);
    const [ loadingForm, setLoadingForm ] = useState(false);
    const [ success, setSuccess ] = useState(false);

    const data = null;

    return (
        <WrapperStyled className="box_create_user">
            
            {openModalImageProfile &&
                <ModalImageProfile 
                    openModalImageProfile={openModalImageProfile} 
                    setOpenModalImageProfile={setOpenModalImageProfile} 
                />
            }

            <ScheduleCreate 
                open={scheduleCreateOpen} 
                setOpen={setScheduleCreateOpen} 
            />

            {!success ? 
                <Form 
                    data={data}
                    action="create"
                    loadingForm={loadingForm}
                    setLoadingForm={setLoadingForm}
                    success={success}
                    setSuccess={setSuccess}
                />
            :
                <SuccessForm 
                    image={successImage} 
                    actionSuccessText={t("texts.Usuario agregado con exito.")}
                    resultSuccessText={t("texts.Ahora se puede encontrar a este usuario activo en la lista de usuarios")}
                    buttonBackToListText={t("texts.Volver a listado de usuarios")}
                    buttonCreateNewText={t("texts.Crear usuario nuevo")}
                    buttonBackToListStyle={"normal"}
                    buttonCreateNewStyle={"invert"}
                    createNew={"/users/create"}
                    backTo={"/users"}
                    setSuccess={setSuccess}
                />
            }
        </WrapperStyled>
    )
                
}

export default CreateContainer;
