import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from "./Sidebar";
import Header from "./Header";
import { ContentContext } from '../../context';
import { useTranslation } from 'react-i18next';
import LoaderComponent from '../elements/LoaderComponent';

const Wrapper = styled.div`
    min-height: 100%;
    height: 100%;
`
const ContentStyled = styled.div`
    width: calc(100% - 90px);
    margin-left: 90px;
    min-height: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 35px 30px 35px;
    >.inner{
        display: flex;
        min-height: calc(100% - 90px);
        padding-top: 75px;
        width: 100%;
        padding-bottom: 25px;
    }
`
const LoaderStyled = styled.div`
    padding-left: 25px;
    padding-top: 25px;
`

const Layout = ({ children }) => {

    const { t, i18n } = useTranslation();
    const { basicData } = useContext(ContentContext);

    const [ openDrawer, setOpenDrawer ] = useState(false);
    const [ menuVariant, setMenuVarian ] = useState("permanent");

    useEffect(() => {
        
        if(basicData !== null){
            if(Object.keys(basicData).length){
                console.log("🏄", basicData.user_language.toLowerCase());
                if("user_language" in basicData){
                    i18n.changeLanguage(basicData.user_language.toLowerCase());
                }else{
                    i18n.changeLanguage("en");
                }

                /*
                if(basicData.user_id === "66ffefcc9be7e261ce1cdbc4"){
                    i18n.changeLanguage("en");
                }else{
                    if("user_language" in basicData){
                        i18n.changeLanguage("es");//basicData.user_language
                    }
                }
                */
                //console.log("🏄", basicData.user_language);
            }
        }
    
      return () => {}
    }, [basicData])
    
    if(basicData === null){
        return(
            <LoaderStyled>
                <LoaderComponent />
            </LoaderStyled>
        )
    }

    return (
        <Wrapper className="layout">
            <Sidebar 
                openDrawer={openDrawer} 
                setOpenDrawer={setOpenDrawer} 
                menuVariant={menuVariant}
                setMenuVarian={setMenuVarian}
            />
            <Header 
                openDrawer={openDrawer} 
                setOpenDrawer={setOpenDrawer} 
                menuVariant={menuVariant}
                setMenuVarian={setMenuVarian}
            />
            <ContentStyled>
                <div className="inner">
                    { children }
                </div>
            </ContentStyled>
        </Wrapper>
    )
}

export default Layout
