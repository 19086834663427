import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import LoaderComponent from '../elements/LoaderComponent';
import IconCustom from '../elements/IconCustom';                     
import ButtonCustom from '../elements/ButtonCustom';
import ButtonTextCustom from '../elements/ButtonTextCustom';
import ModalImage from '../elements/Modals/ModalImage';
import ScheduleCreate from '../elements/Dialogs/ScheduleCreate';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Question from './Question';

import { FormatPaintIcon, 
         FormatListNumberedIcon,
         CountIcon,
         TaskIcon,
         CoursesMenuIcon,
         CalendarMonthIcon, } from '../../helpers/icons';

import { dataObjectEvaluationsAnswersBasics,
         dataObjectEvaluationsQuestionBasics,
         getQuestionTypeId,
         getTypeAssesmentLabel } from '../../helpers/functions';

import { ContentContext,
         ConfigurationContext } from '../../context';

import { useTranslation } from 'react-i18next';

const WrapperStyled = styled.div`
    display: flex;
    width: 100%;
    /*height: 100%;*/
    justify-content: flex-start;
    flex-direction: column;
    .box_evaluation_container{
        display: flex;
        flex-direction: column;
        width: 100%;

        >.title{
            color: ${props => `rgb(${props.pancolors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        .box_question{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 50px;
            margin-bottom: 25px;
            .question_number{
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 15px;
            }
            .question_type_box{
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                .icon{
                    margin-right: 25px;
                }
                .field_input_type{
                    width: calc(100% - 1100px);
                    margin-right: 20px;
                }
                .field_score{
                    width: 320px;
                    margin-right: 20px;
                }
                .field_penalty_score{
                    width: 320px;
                    margin-right: 20px;
                }
                .field_id_module{
                    width: 400px;
                }
            }
        }
        .evaluation_data{
            display: grid; 
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            width: 100%;

            .element{
                display: flex;
                flex-direction: row;
                align-items: center;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                font-size: 16px;
                .icon{
                    display: flex;
                    svg{
                        fill: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                    }
                }
                .text{
                    font-weight: 600;
                    margin-left: 5px;
                }
                .value{
                    margin-left: 5px;
                }
            }

        }

        .evaluation_questions{
            display: grid; 
            grid-template-columns: repeat(15, 1fr);
            gap: 20px 0;
            width: 100%;
            margin-top: 50px;
            .question_item{
                display: flex;
                flex-direction: row;
                align-items: center;
                .question_bullet{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    height: 50px;
                    border-radius: 25px;
                    background-color: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                    .order_number{
                        color: #FFFFFF;
                        font-size: 25px;
                    }
                }
                .line{
                    width: calc(100% - 50px);
                    height: 2px;
                    background-color: ${props => `rgb(${props.pancolors.black_overwhite})`};  
                }
                &.selected{
                    .question_bullet{
                        cursor: default;
                        background-color: ${props => `rgb(${props.pancolors.dots})`}!important; 
                    }
                }
            }
        }

    }
    

    .box_form{
        display: flex;
        width: 100%;
        .box_column{
            .action_box_evaluation{
                margin-top: 50px;
            }
            .media_slides{
                margin-top: 30px;
                .no_multimedia_content{
                    box-sizing: border-box;
                    padding: 10px 15px 10px 15px;
                    width: 100%;
                    border: 1px dashed ${props => `rgb(${props.pancolors.middle_gray})`};
                    color: ${props => `rgb(${props.pancolors.dark_gray})`};
                    font-weight: 600;
                }
            }
        }
        &.show{
            display: grid;
        }
        &.hide{
            display: none;
        }
    }
    .box_fields{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 40px;
        >.title{
            color: ${props => `rgb(${props.pancolors.black})`};
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 20px;
        }
        >.field{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 25px;
            :last-child{
                margin-bottom: 0;
            }
        }
        .field_description_long{
            margin-bottom: 0!important;
            textarea{
                height: 160px;
            }
        }
        .field_description_short{
            margin-bottom: 0!important;
            textarea{
                height: 120px;
            }
        }
        .bottom_textarea{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            font-size: 14px;
            margin-top: 5px;
            font-weight: 700;
            color: ${props => `rgb(${props.pancolors.dark_gray})`};
            &.error{
                color: ${props => `rgb(${props.pancolors.red})`};
            }
        }
        .box_field_description_long,
        .box_field_description_short{
            width: 100%;
            margin-bottom: 25px!important;

            .error{
                color: ${props => `rgb(${props.pancolors.red})`};
            }
        }
    }
    .actions{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        margin-top: 15px;
        .bt_prev_question{
            margin-right: 10px;
        }
        .bt_next_question{

        }
        .bt_submit{

        }
        .bt_program_activation{
            margin-left: 10px;
        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
    .evaluation_actions{
        display: flex;
        flex-direction: row;
        box-sizing: border-box;
        padding: 20px 0;
        margin-top: 40px;
        border-top: 1px solid ${props => `rgb(${props.pancolors.middle_gray})`};
        >.loader{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            height: 44px;
            width: 44px;
            margin: 0;
            border-radius: 4px;
            background-color: ${props => `rgb(${props.pancolors.primary})`};
        }
        .bt_program_activation{
            margin-left: 10px;
        }
        .bt_cancel{
            margin-left: 10px;
        }
    }
`

const EvaluationContainer = (props) => {

    const { t } = useTranslation();

    const navigate = useNavigate();
    const { id_course, id_module } = useParams();

    const { panColors,
            userCountrySelected } = useContext(ConfigurationContext);
    
    const { assessment,
            basicData,
            createCreatedAssessment,
            editCreatedAssessment, } = useContext(ContentContext);
            
    const { data = null,
            lang = null,
            scope,
            loadingForm,
            setLoadingForm,
            setSuccess,
            contentByLang, 
            setContentByLang,
            idCourse, 
            idModule,
            assessmentType,
            setIdAssessment } = props;
            
    const [ openModalImage, setOpenModalImage ] = useState(false);
    
    const [ croppedImage, setCroppedImage ] = useState(null);
    const [ uploadRoute, setUploadRoute ] = useState(null);
    const [ questionSelected, setQuestionSelected ] = useState(null);
    const [ questionSelectedOrder, setQuestionSelectedOrder ] = useState(null);
    const [ createEvaluationStatus, setCreateEvaluationStatus ] = useState(false);
    const [ blockAction, setBlockAction ] = useState(false);
    const [ scheduleCreateOpen, setScheduleCreateOpen ] = useState(false);
    const [ datePublished, setDatePublished ] = useState(null);
    const [ langHaveError, setLangHaveError ] = useState([]);

    const handleEditQuestion = (order, question) => {
        setQuestionSelectedOrder(order);
        setQuestionSelected(question);
    }

    
    const handlePrev = () => {
        setCreateEvaluationStatus(false);
        const prev_question = questionSelectedOrder - 1;
        setQuestionSelectedOrder(prev_question);
        setQuestionSelected(contentByLang.questions[prev_question]);
    }

    const handleNext = async (isConfirmation = false) => {
        setLangHaveError([]);
        await goNext(isConfirmation);
        if(!isConfirmation){
            
            setCreateEvaluationStatus(false);
            if(questionSelectedOrder < (contentByLang.questions.length - 1)){
                const next_question = questionSelectedOrder + 1;
                setQuestionSelectedOrder(next_question);
                setQuestionSelected(contentByLang.questions[next_question]);
            }
        }else{
            setCreateEvaluationStatus(true);
        }
        
    }

    const goNext = (isConfirmation = false) => {
        return new Promise((resolve, reject) => {
            setCreateEvaluationStatus(false);
            const questionSelected_to_validate = {...questionSelected};

            let langsError = [];
            let haveError = false;
            let haveMediaQuestion = false;
            let haveMediaAnswer = 0;
                
            Object.keys(questionSelected_to_validate).map(function(key) {

                if(key !== "translations"){
                    if(questionSelected_to_validate[key].required){
                        if(!questionSelected_to_validate[key].value){
                            questionSelected_to_validate[key].error = true;
                            haveError = true;
                        }else{
                            questionSelected_to_validate[key].error = false;
                        }
                    }
                }
                
                if(key === "translations"){
                    if(questionSelected_to_validate.input_type.value === "single_choice"){
                        Object.keys(questionSelected.translations).map(function(lang_key) {
                            let lang_error = false;
                            Object.keys(questionSelected.translations[lang_key]).map(function(lang_inner_key) {
                                if(lang_inner_key === "secondary_actions"){

                                    const correct_ok = correctSelected(questionSelected.translations[lang_key][lang_inner_key], true);
                                    questionSelected.translations[lang_key][lang_inner_key].map((secondary, index) => {
                                        Object.keys(secondary).map(function(sakey) {
                                            if(secondary[sakey].required){
                                                if(!secondary[sakey].value.length){
                                                    secondary[sakey].error = true;
                                                    lang_error = true;
                                                    haveError = true;
                                                }else{
                                                    secondary[sakey].error = false;
                                                }
                                            }
                                            if(sakey === "correct"){
                                                if(!correct_ok){
                                                    secondary[sakey].error = true;
                                                    lang_error = true;
                                                    haveError = true;
                                                }else{
                                                    secondary[sakey].error = false;
                                                }
                                            }
                                        })
                                        return secondary;
                                    })

                                }else{

                                    if(lang_inner_key === "title"){
                                        if(!questionSelected.translations[lang_key][lang_inner_key].value.length){
                                            questionSelected.translations[lang_key][lang_inner_key].error = true;
                                            lang_error = true;
                                            haveError = true;
                                        }else{
                                            questionSelected.translations[lang_key][lang_inner_key].error = false;
                                        }
                                    }

                                }
                            })

                            if(lang_error){
                                langsError.push(lang_key);
                            }

                        })

                    }

                    if(questionSelected_to_validate.input_type.value === "multiple_choice"){
                        Object.keys(questionSelected.translations).map(function(lang_key) {
                            let lang_error = false;
                            Object.keys(questionSelected.translations[lang_key]).map(function(lang_inner_key) {
                                if(lang_inner_key === "secondary_actions"){

                                    const correct_ok = correctSelected(questionSelected_to_validate.translations[lang_key][lang_inner_key], false);
                                    questionSelected.translations[lang_key][lang_inner_key].map((secondary, index) => {
                                        Object.keys(secondary).map(function(sakey) {
                                            if(secondary[sakey].required){
                                                if(!secondary[sakey].value.length){
                                                    secondary[sakey].error = true;
                                                    lang_error = true;
                                                    haveError = true;
                                                }else{
                                                    secondary[sakey].error = false;
                                                }
                                            }
                                            if(sakey === "correct"){
                                                if(!correct_ok){
                                                    secondary[sakey].error = true;
                                                    lang_error = true;
                                                    haveError = true;
                                                }else{
                                                    secondary[sakey].error = false;
                                                }
                                            }
                                        })
                                    })

                                }else{
                                    
                                    if(lang_inner_key === "title"){
                                        if(!questionSelected.translations[lang_key][lang_inner_key].value.length){
                                            questionSelected.translations[lang_key][lang_inner_key].error = true;
                                            lang_error = true;
                                            haveError = true;
                                        }else{
                                            questionSelected.translations[lang_key][lang_inner_key].error = false;
                                        }
                                    }

                                }
                            })

                            if(lang_error){
                                langsError.push(lang_key);
                            }

                        })
                    }

                    if(questionSelected_to_validate.input_type.value === "single_choice_image"){

                        Object.keys(questionSelected.translations).map(function(lang_key) {
                            let lang_error = false;
                            Object.keys(questionSelected.translations[lang_key]).map(function(lang_inner_key) {
                                if(lang_inner_key === "secondary_actions"){
                                    
                                    const correct_ok = correctSelected(questionSelected_to_validate.translations[lang_key][lang_inner_key], true);
                                    questionSelected_to_validate.translations[lang_key][lang_inner_key].map((secondary, index) => {
                                        Object.keys(secondary).map(function(sakey) {
                                            if(secondary[sakey].required){
                                                if(sakey === "media"){
                                                    if(!secondary[sakey].value.length){
                                                        secondary[sakey].error = true;
                                                        lang_error = true;
                                                        haveError = true;
                                                    }else{
                                                        secondary[sakey].error = false;
                                                    }
                                                }else{
                                                    if(!secondary[sakey].value.length){
                                                        secondary[sakey].error = true;
                                                        lang_error = true;
                                                        haveError = true;
                                                    }else{
                                                        secondary[sakey].error = false;
                                                    }
                                                }
                                            }
                                            if(sakey === "correct"){
                                                if(!correct_ok){
                                                    secondary[sakey].error = true;
                                                    lang_error = true;
                                                    haveError = true;
                                                }else{
                                                    secondary[sakey].error = false;
                                                }
                                            }
                                        })
                                        return secondary;
                                    })

                                }else{

                                    if(lang_inner_key === "title"){
                                        if(!questionSelected_to_validate.translations[lang_key][lang_inner_key].value.length){
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = true;
                                            lang_error = true;
                                            haveError = true;
                                        }else{
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = false;
                                        }
                                    }

                                }
                            })

                            if(lang_error){
                                langsError.push(lang_key);
                            }

                        })
                    }
                    
                    if(questionSelected_to_validate.input_type.value === "multiple_choice_image"){

                        Object.keys(questionSelected.translations).map(function(lang_key) {
                            let lang_error = false;
                            Object.keys(questionSelected.translations[lang_key]).map(function(lang_inner_key) {
                                if(lang_inner_key === "secondary_actions"){

                                    const correct_ok = correctSelected(questionSelected_to_validate.translations[lang_key][lang_inner_key], false);
                                    questionSelected_to_validate.translations[lang_key][lang_inner_key].map((secondary, index) => {
                                        Object.keys(secondary).map(function(sakey) {
                                            if(secondary[sakey].required){
                                                if(sakey === "media"){
                                                    if(!secondary[sakey].value.length){
                                                        secondary[sakey].error = true;
                                                        lang_error = true;
                                                        haveError = true;
                                                    }else{
                                                        secondary[sakey].error = false;
                                                    }
                                                }else{
                                                    if(!secondary[sakey].value.length){
                                                        secondary[sakey].error = true;
                                                        lang_error = true;
                                                        haveError = true;
                                                    }else{
                                                        secondary[sakey].error = false;
                                                    }
                                                }
                                            }
                                            if(sakey === "correct"){
                                                if(!correct_ok){
                                                    secondary[sakey].error = true;
                                                    lang_error = true;
                                                    haveError = true;
                                                }else{
                                                    secondary[sakey].error = false;
                                                }
                                            }
                                        })
                                        return secondary;
                                    })

                                }else{

                                    if(lang_inner_key === "title"){
                                        if(!questionSelected_to_validate.translations[lang_key][lang_inner_key].value.length){
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = true;
                                            lang_error = true;
                                            haveError = true;
                                        }else{
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = false;
                                        }
                                    }

                                }
                            })

                            if(lang_error){
                                langsError.push(lang_key);
                            }

                        })
                    }

                    if(questionSelected_to_validate.input_type.value === "comments"){

                        Object.keys(questionSelected.translations).map(function(lang_key) {
                            let lang_error = false;
                            Object.keys(questionSelected.translations[lang_key]).map(function(lang_inner_key) {
                                if(lang_inner_key === "secondary_actions"){
                                    
                                    if(!questionSelected_to_validate.translations[lang_key][lang_inner_key].length){
                                        questionSelected_to_validate.translations[lang_key]["secondary_actions_error"] = true;
                                        lang_error = true;
                                        haveError = true;
                                    }else{
                                        questionSelected_to_validate.translations[lang_key]["secondary_actions_error"] = false;
                                    }

                                }else{

                                    if(lang_inner_key === "title"){
                                        if(!questionSelected_to_validate.translations[lang_key][lang_inner_key].value.length){
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = true;
                                            lang_error = true;
                                            haveError = true;
                                        }else{
                                            questionSelected_to_validate.translations[lang_key][lang_inner_key].error = false;
                                        }
                                    }

                                }
                            })

                            if(lang_error){
                                langsError.push(lang_key);
                            }
                            
                        })
                    }

                }
                

            })

            
            
            setQuestionSelected(questionSelected_to_validate);

            if(!haveError){
                const question_ok = data.questions.map((question, index) => {
                    if((questionSelectedOrder) === index){
                        return questionSelected_to_validate;
                    }else{
                        return question;
                    }
                })
                
                let contentByLangUpdate = {...contentByLang};
                contentByLangUpdate.questions = question_ok;
                setContentByLang(contentByLangUpdate);
                
                resolve({status: true, haveMediaQuestion: haveMediaQuestion, haveMediaAnswer: haveMediaAnswer});
            }else{
                setLangHaveError(langsError);
                setCreateEvaluationStatus(false);
                //reject({status: false});//
                reject("Failure: The promise has failed!");
            }
            
        })
        
    }   
    

    const correctSelected = (secondary_actions, single) => {

        let corrects = 0;
        secondary_actions.map((secondary, index) => {
            Object.keys(secondary).map(function(key) {
                if(key === "correct"){
                    if(secondary[key].value === true){
                        corrects++;
                    }
                }
            })
        })

        if(single){
            if(corrects === 1){
                return true;
            }
        }else{
            if(corrects > 1){
                return true;
            }
        }
        return false;
        
    }

    const handleSendEvaluation = () => {
        //setLoadingForm(true);
        const _dataCloned = cloneDeep(contentByLang);

        //console.log("_dataCloned", _dataCloned);
        //return null;
        //return null;
        if(scope === "create"){

            Object.keys(_dataCloned).map(function(key) {
                _dataCloned.translations = {};
                if(key !== "questions"){
                    if(key === "can_repeat" || key === "can_repeat_failure" || key === "can_repeat_success" || key === "random"){
                        _dataCloned[key] = _dataCloned[key].value ? true : false;
                    }else{
                        if(key !== 'segments'){
                            _dataCloned[key] = _dataCloned[key].value;
                        }
                        if(key === 'segments'){
                            _dataCloned[key] = _dataCloned[key];
                        }
                    }

                }
                if(key === "questions"){

                    userCountrySelected.languages.map((lang, index) => {
                        _dataCloned.translations[lang.language_identifier] = {};
                        let questions = [];
                        for(let i = 0; i < _dataCloned[key].length; i++){

                            Object.keys(_dataCloned[key][i]).map(function(elkey) {
                                if(elkey !== "secondary_actions" && elkey !== "translations" && elkey !== "input_type"){
                                    _dataCloned[key][i][elkey] = contentByLang[key][i][elkey].value ? contentByLang[key][i][elkey].value : "";
                                }
                                if(elkey === "input_type"){
                                    let question_type_id = getQuestionTypeId(basicData.questions_type, contentByLang[key][i][elkey].value/*_dataCloned[key][i][elkey].value*/);
                                    _dataCloned[key][i][elkey] = question_type_id ? question_type_id : "";
                                }
                            })

                            Object.keys(_dataCloned[key][i].translations[lang.language_identifier]).map(function(langkey) {
                                if(langkey !== "secondary_actions"){
                                    //
                                    _dataCloned[key][i].translations[lang.language_identifier][langkey] = contentByLang[key][i].translations[lang.language_identifier][langkey].value;
                                }else{
                                    for(let e = 0; e < _dataCloned[key][i].translations[lang.language_identifier][langkey].length; e++){
                                        Object.keys(_dataCloned[key][i].translations[lang.language_identifier][langkey][e]).map(function(secondarykey) {
                                            _dataCloned[key][i].translations[lang.language_identifier][langkey][e][secondarykey] = contentByLang[key][i].translations[lang.language_identifier][langkey][e][secondarykey].value;
                                        })
                                    }
                                }
                            })
                            
                            let objetoMerged = { ..._dataCloned[key][i], ..._dataCloned[key][i].translations[lang.language_identifier] };
                            delete objetoMerged.translations; 
                            questions.push(objetoMerged);
                        }
                        _dataCloned.translations[lang.language_identifier][key] = questions;
                    })
                    
                }
            })

            _dataCloned.segments = JSON.stringify(_dataCloned.segments);
            _dataCloned.translations = JSON.stringify(_dataCloned.translations);

            if(id_course){
                _dataCloned["id_course"] = id_course;
            }
            if(id_module){
                _dataCloned["id_content"] = id_module;
            }

            if(datePublished){
                _dataCloned["date_published"] = dayjs(datePublished).format('YYYY-MM-DD HH:mm');
            }

            delete _dataCloned["questions"];
            delete _dataCloned["question_quantity_toload"];
            delete _dataCloned["segments"]; 

            
                
            const response = createCreatedAssessment(_dataCloned);
            response.then((res) => {
                setIdAssessment(res.data.single.assessment._id);
                setSuccess(true);
                setLoadingForm(false);
            })

        }
        if(scope === "edit"){
            

            console.log("_dataCloned", _dataCloned)
            //return null;
            Object.keys(_dataCloned).map(function(key) {
                console.log("key", key, _dataCloned[key]);
                if(key !== "translations"){
                    if(key !== "secondary_actions" && key !== "input_type" && key !== "segments"){
                        _dataCloned[key] = _dataCloned[key].value ? _dataCloned[key].value : "";
                    }
                    if(key === "segments"){
                        _dataCloned[key] = JSON.stringify(_dataCloned[key]);
                    }
                }
                if(key === "translations"){
                    Object.keys(_dataCloned[key]).map(function(lang) {
                        console.log("------> ", _dataCloned[key][lang])
                        _dataCloned[key][lang].questions.map(function(question) {
                            Object.keys(question).map(function(q) {
                                if(q !== "secondary_actions" && q !== "input_type" && q !== "segments"){
                                    question[q] = question[q].value ? question[q].value : "";
                                }
                                if(q === "input_type"){
                                    question[q] = getQuestionTypeId(basicData.questions_type, question[q].value);
                                }
                                if(q === "secondary_actions"){
                                    question.secondary_actions.map(function(action) {
                                        Object.keys(action).map(function(action_key) {
                                            action[action_key] = action[action_key].value;
                                        })
                                    })
                                }
                            })
                            //_dataCloned[key][lang][question].map()
                        })
                        
                    })
                }
            })

            _dataCloned.translations = JSON.stringify(_dataCloned.translations);//_dataCloned.translations;
           
            if(datePublished){
                _dataCloned["date_published"] = dayjs(datePublished).format('YYYY-MM-DD HH:mm');
            }

            _dataCloned["id_assessment"] = assessment._id;

            delete _dataCloned["questions"];
            delete _dataCloned["question_quantity_toload"];

            //console.log("_dataCloned", _dataCloned)
            //return null;

            const response = editCreatedAssessment(_dataCloned);
            response.then((res) => {
                setSuccess(true);
                setLoadingForm(false);
            })

            //delete _dataCloned["questions"];

            
            /*
            Object.keys(_dataCloned).map(function(key) {
                console.log("key", key);
                if(key === "can_repeat" || key === "can_repeat_failure" || key === "can_repeat_success" || key === "random"){
                    _dataCloned[key] = _dataCloned[key].value ? true : false;
                }else{
                    
                    if(key === 'segments'){
                        _dataCloned[key] = _dataCloned[key];
                    }

                    if(key === "translations"){

                        userCountrySelected.languages.map((lang__, index) => {
                            if(_dataCloned[key][lang__.language_identifier]){

                                    _dataCloned[key][lang__.language_identifier].questions.map((question, index) => {

                                        Object.keys(question).map(function(key_question) {
                                            if(key_question !== 'secondary_actions'){
                                                if(key_question === 'input_type'){
                                                    question[key_question] = getQuestionTypeId(basicData.questions_type, question[key_question].value);
                                                }
                                                if(key_question !== 'input_type'){
                                                    question[key_question] = question[key_question].value ? question[key_question].value : "";
                                                }
                                            }
                                            if(key_question === 'secondary_actions'){
                                                question[key_question].map((answer, n) => {
                                                    Object.keys(answer).map(function(key_answer) {
                                                        answer[key_answer] =  answer[key_answer].value ? answer[key_answer].value : "";
                                                    })
                                                })
                                            }
                                        })

                                    })
                                
                            }else{
                            }
                        })

                        userCountrySelected.languages.map((lang__, index) => {
                            if(!_dataCloned[key][lang__.language_identifier]){

                                _dataCloned[key][lang__.language_identifier] = {questions: []};
                                _dataCloned[key][lang__.language_identifier].questions = getQuestionByLang(contentByLang.questions, lang__.language_identifier);

                            }
                        })

                        

                    }
                    
                    if(key !== 'segments' && key !== 'translations'){
                       _dataCloned[key] = _dataCloned[key].value ? _dataCloned[key].value : "";
                    }
                }
            })

            _dataCloned.segments = JSON.stringify(_dataCloned.segments);
            _dataCloned.translations = JSON.stringify(_dataCloned.translations);

            _dataCloned["id_assessment"] = assessment._id;
            */








            /*
            Object.keys(_dataCloned).map(function(key) {
                _dataCloned.translations = {};
                if(key !== "questions"){
                    if(key === "can_repeat" || key === "can_repeat_failure" || key === "can_repeat_success" || key === "random"){
                        _dataCloned[key] = _dataCloned[key].value ? true : false;
                    }else{
                        if(key !== 'segments'){
                            _dataCloned[key] = _dataCloned[key].value;
                        }
                        if(key === 'segments'){
                            _dataCloned[key] = _dataCloned[key];
                        }
                    }

                }
                if(key === "questions"){

                    userCountrySelected.languages.map((lang, index) => {
                        _dataCloned.translations[lang.language_identifier] = {};
                        let questions = [];
                        for(let i = 0; i < _dataCloned[key].length; i++){

                            Object.keys(_dataCloned[key][i]).map(function(elkey) {
                                if(elkey !== "secondary_actions" && elkey !== "translations" && elkey !== "input_type"){
                                    _dataCloned[key][i][elkey] = contentByLang[key][i][elkey].value ? contentByLang[key][i][elkey].value : "";
                                }
                                if(elkey === "input_type"){
                                    let question_type_id = getQuestionTypeId(basicData.questions_type, contentByLang[key][i][elkey].value);
                                    console.log("question_type_id", question_type_id);
                                    console.log("[key][i][elkey]", key, i, elkey);
                                    _dataCloned[key][i][elkey] = "asdasdasd";//question_type_id ? question_type_id : "";
                                    console.log("[key][i][elkey]", key, i, elkey, _dataCloned[key][i][elkey]);
                                }
                            })

                            Object.keys(_dataCloned[key][i].translations[lang.language_identifier]).map(function(langkey) {
                                if(langkey !== "secondary_actions"){
                                    //
                                    _dataCloned[key][i].translations[lang.language_identifier][langkey] = contentByLang[key][i].translations[lang.language_identifier][langkey].value;
                                }else{
                                    for(let e = 0; e < _dataCloned[key][i].translations[lang.language_identifier][langkey].length; e++){
                                        Object.keys(_dataCloned[key][i].translations[lang.language_identifier][langkey][e]).map(function(secondarykey) {
                                            _dataCloned[key][i].translations[lang.language_identifier][langkey][e][secondarykey] = contentByLang[key][i].translations[lang.language_identifier][langkey][e][secondarykey].value;
                                        })
                                    }
                                }
                            })
                            console.log("----> ", _dataCloned, _dataCloned[key][i], _dataCloned[key][i].translations[lang.language_identifier])
                            let objetoMerged = {};//{ ..._dataCloned[key][i], ..._dataCloned[key][i].translations[lang.language_identifier] };
                            console.log("objetoMerged", objetoMerged)
                            delete objetoMerged.translations; 
                            questions.push(objetoMerged);
                        }
                        console.log("questions", questions);
                        _dataCloned.translations[lang.language_identifier][key] = questions;
                    })
                    
                }
            })

            

            _dataCloned.segments = JSON.stringify(_dataCloned.segments);
            _dataCloned.translations = JSON.stringify(_dataCloned.translations);

            if(idCourse){
                _dataCloned["id_course"] = idCourse;
            }
            if(idModule){
                _dataCloned["id_content"] = idModule;
            }

            if(datePublished){
                _dataCloned["date_published"] = dayjs(datePublished).format('YYYY-MM-DD HH:mm');
            }

            delete _dataCloned["questions"];
            delete _dataCloned["question_quantity_toload"];
            delete _dataCloned["segments"]; 
            
            //console.log("_dataCloned", _dataCloned);
            //return null;

            const response = editCreatedAssessment(_dataCloned);
            response.then((res) => {
                setSuccess(true);
                setLoadingForm(false);
            })
            */





        }

    }

    const getQuestionByLang = (questions, lang_) => {
        let questions_updates = [];
        let questions_arr = [];
        let input_type_arr = [];


        
        // questions_arr = questions.map((question, index) => {
        //     question.translations[lang_]
        // })

        for(let index = 0; index < questions.length; index++) {

            let question_type_id = getQuestionTypeId(basicData.questions_type, questions[index].input_type.value);
            input_type_arr.push(question_type_id);
            questions_arr.push(questions[index].translations[lang_]);

        }

        questions_updates = questions_arr.map((question, index) => {
            
            Object.keys(question).map(function(key) {
                
                if(key !== "secondary_actions"){
                    if(key !== "input_type"){
                        question[key] = question[key].value ? question[key].value : "";
                    }else{
                        question[key] = input_type_arr[index];
                    }
                }
                
                if(key === "secondary_actions"){
                    for(let e = 0; e < question[key].length; e++) {
                        Object.keys(question[key][e]).map(function(sakey) {
                            question[key][e][sakey] = question[key][e][sakey].value ? question[key][e][sakey].value : "";
                        })
                    }
                }
            })

            return question;

        })

        return questions_arr;
        
    }

    const handleProgramActivation = () => {
        setScheduleCreateOpen(true);
    }

    const handleCancel = () => {
        if(scope === "create"){
            if(id_course){
                navigate(`/courses`);
            }
            if(id_module){
                navigate(`/courses`);
            }
        }
        if(scope === "edit"){
            if(id_course){
                navigate(`/course/${id_course}/evaluation/${assessment._id}/view`);
            }
            if(id_module){
                navigate(`/module/${id_module}/evaluation/${assessment._id}/view`);
            }
        }
        
    }

    useEffect(() => {
        
        if(data){
            
            
            if(scope === "create"){

                let arrQuestions = [];
                
                const question_lenght = parseInt(contentByLang.question_quantity_toload.value);
                for(let i = 0; i < question_lenght; i++){
                    arrQuestions.push(dataObjectEvaluationsQuestionBasics(null, null));
                }

                for(let i = 0; i < arrQuestions.length; i++){
                    let questionLangs = {}
                    userCountrySelected.languages.map((lang, index) => {
                        questionLangs[lang.language_identifier] = dataObjectEvaluationsAnswersBasics(null, null);
                    })

                    arrQuestions[i].translations = questionLangs;

                    delete arrQuestions[i].title;
                    delete arrQuestions[i].description;
                }

                let contentByLangUpdate = {...contentByLang};
                contentByLangUpdate["questions"] = arrQuestions;
                
                setContentByLang(contentByLangUpdate);
                handleEditQuestion(0, arrQuestions[0]);
                console.log("contentByLangUpdate", contentByLangUpdate)

            }

            if(scope === "edit"){
                setQuestionSelectedOrder(0)
                setQuestionSelected(contentByLang.questions[0]);
            }

        }

        return () => {}
    }, [])

    
    
    
    return (
        <WrapperStyled pancolors={panColors}>

            {openModalImage &&
                <ModalImage 
                    openModalImage={openModalImage} 
                    setOpenModalImage={setOpenModalImage} 
                    croppedImage={croppedImage}
                    setCroppedImage={setCroppedImage}
                    uploadRoute={uploadRoute}
                    setUploadRoute={setUploadRoute}
                    scope={"cover_image"}
                    maxSize={""}
                />
            }

            <ScheduleCreate 
                open={scheduleCreateOpen} 
                setOpen={setScheduleCreateOpen} 
                date={datePublished}
                setDate={setDatePublished}
                handleSubmit={handleSendEvaluation}
            />
            
            <div className="box_evaluation_container">
                <div className="title">
                    {t("texts.Datos de la evaluacion")}
                </div>

                <div className="evaluation_data">
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<CoursesMenuIcon />} />
                        </div>
                        <div className="text">{t("texts.Cantidad de preguntas a evaluar")}:</div>
                        <div className="value">{contentByLang?.count_questions?.value}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<FormatListNumberedIcon />} />
                        </div>
                        <div className="text">{t("texts.Puede repetir indefinidamente")}:</div>
                        <div className="value">{contentByLang?.can_repeat?.value ? "Si" : "No"}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<FormatListNumberedIcon />} />
                        </div>
                        <div className="text">{t("texts.Puede repetir despues de responder mal")}:</div>
                        <div className="value">{contentByLang?.can_repeat_failure?.value ? "Si" : "No"}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<FormatListNumberedIcon />} />
                        </div>
                        <div className="text">{t("texts.Puede repetir despues de responder bien")}:</div>
                        <div className="value">{contentByLang?.can_repeat_success?.value ? "Si" : "No"}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<CountIcon />} />
                        </div>
                        <div className="text">{t("texts.Cantidad de preguntas a cargar")}:</div>
                        <div className="value">{contentByLang?.question_quantity_toload?.value}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<FormatPaintIcon />} />
                        </div>
                        <div className="text">{t("texts.Estilo de evaluacion")}:</div>
                        <div className="value">{getTypeAssesmentLabel(basicData.assessment_type, contentByLang?.type_assessment?.value)}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<TaskIcon />} />
                        </div>
                        <div className="text">{t("texts.Score minimo para aprobar evaluacion")}:</div>
                        <div className="value">{contentByLang?.approval_rate?.value}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<TaskIcon />} />
                        </div>
                        <div className="text">{t("texts.Aprobar esta evaluacion suma")}:</div>
                        <div className="value">{contentByLang?.approval_rate?.value}</div>
                    </div>
                    <div className="element">
                        <div className="icon">
                            <IconCustom icon={<TaskIcon />} />
                        </div>
                        <div className="text">{t("texts.Desaprobar esta evaluacion resta")}:</div>
                        <div className="value">{contentByLang?.approval_rate?.value}</div>
                    </div>
                </div>

                <div className='evaluation_questions'>
                    {contentByLang?.questions?.map((item, index) => {
                        let order = index;// + 1;
                        return(
                            <div key={uuid()} className={`question_item ${questionSelectedOrder === order ? " selected" : ""}`}>
                                <div className="question_bullet">
                                    <div className="order_number">{ order + 1 }</div>
                                </div>
                                {index === 0 || index % (contentByLang.questions.length-1) !== 0 ?
                                    index !== (contentByLang.questions.length-1) ?
                                    <div className="line"></div>
                                    : "" : ""
                                }
                            </div>
                        )
                    })}
                    
                </div>
                
                {data && questionSelected &&
                    <Question 
                        evaluationData={data}
                        questionSelected={questionSelected}
                        setQuestionSelected={setQuestionSelected}
                        questions={data.questions}
                        questionSelectedOrder={questionSelectedOrder}
                        handlePrev={handlePrev}
                        handleNext={handleNext}
                        contentByLang={contentByLang} 
                        setContentByLang={setContentByLang}
                        blockAction={blockAction}
                        setBlockAction={setBlockAction}
                        scope={scope}
                        lang={lang}
                        langHaveError={langHaveError}
                        assessmentType={assessmentType}
                    />
                }


                <div className='actions'>
                    <ButtonCustom 
                        className="bt_prev_question"
                        text={t("texts.Pregunta Anterior")}
                        onClick={handlePrev}
                        style_type="normal"
                        
                        icon={null}
                        background={{ normal: panColors.primary, hover: panColors.white }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.white, hover: panColors.primary }}

                        disabled={!blockAction ? (questionSelectedOrder === 0 ? true : false) : true}
                    />
                    {questionSelectedOrder === (data?.questions?.length - 1) ?
                        <ButtonCustom 
                            className="bt_next_question"
                            text={t("texts.Confirmar")}
                            onClick={() => handleNext(true)}
                            style_type="normal"
                            
                            icon={null}
                            background={{ normal: panColors.primary, hover: panColors.white }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.white, hover: panColors.primary }}

                            disabled={!blockAction ? false : blockAction}
                        />
                        :
                        <ButtonCustom 
                            className="bt_next_question"
                            text={t("texts.Pregunta Siguiente")}
                            onClick={() => handleNext()}
                            style_type="normal"
                            
                            icon={null}
                            background={{ normal: panColors.primary, hover: panColors.white }}
                            border={{ normal: panColors.primary, hover: panColors.primary }}
                            textcolor={{ normal: panColors.white, hover: panColors.primary }}

                            disabled={!blockAction ? false : blockAction}
                        />
                    }
                </div>


            </div>

            <div className="evaluation_actions">
                {!loadingForm ?
                    <ButtonCustom 
                        className="bt_create_evaluation"
                        text={t("texts.Guardar Evaluacion")}
                        onClick={() => handleSendEvaluation() }
                        style_type="normal"
                        
                        icon={null}
                        background={{ normal: panColors.primary, hover: panColors.white }}
                        border={{ normal: panColors.primary, hover: panColors.primary }}
                        textcolor={{ normal: panColors.white, hover: panColors.primary }}
                        loadingForm={loadingForm}
                        disabled={!createEvaluationStatus}
                    />
                    :
                    <div className='loader'>
                        <LoaderComponent size={25} color={panColors.white} />
                    </div>
                }
                <ButtonCustom 
                    className="bt_program_activation"
                    text={t("texts.Programar activacion de la evaluacion")}
                    onClick={() => { !loadingForm && handleProgramActivation() }}
                    style_type="invert"

                    icon={<CalendarMonthIcon />}
                    background={{ normal: panColors.white, hover: panColors.primary }}
                    border={{ normal: panColors.primary, hover: panColors.primary }}
                    textcolor={{ normal: panColors.primary, hover: panColors.white }}
                    disabled={loadingForm || !createEvaluationStatus}
                />
                <ButtonTextCustom 
                    className="bt_cancel"
                    text={t("texts.Cancelar")}
                    onClick={() => { !loadingForm && handleCancel() }}

                    icon={null}
                    background={{ normal: "", hover: "" }}
                    border={{ normal: "", hover: "" }}
                    textcolor={{ normal: panColors.red, hover: panColors.red }}
                    disabled={loadingForm}
                />
            </div>
            
        </WrapperStyled>
    );
}

export default React.memo(EvaluationContainer);