import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { ConfigurationContext, ContentContext } from '../../context';
import SelectCustom from './SelectCustom';

import { LocalizationAreaIcon, } from '../../helpers/icons';

import { getLabelName } from '../../helpers/functions';

const Wrapper = styled.div`
    width: 100%;
    .box_labels{
        width: 100%;
        .field_product_labels{
            width: 100%!important;
        }
        .box_add_labels{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .field_labels_add{
                width: 100%;
            }
            .bt_add_label{
                margin: 15px 0;
                padding: 0;
                height: auto;
                p{
                    padding: 0;
                    margin: 0;
                }
            }
        }
        .box_labels_selected{
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            flex-wrap: wrap;
            .label{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                border: 2px solid ${props => `rgb(${props.pancolors.dark_gray})`};
                border-radius: 8px;
                box-sizing: border-box;
                padding: 10px 15px;
                margin-bottom: 10px;
                margin-right: 10px;
                color: ${props => `rgb(${props.pancolors.dark_gray})`};
                font-weight: 600;
                &.exist{
                    border-color: ${props => `rgb(${props.pancolors.dots})`};
                    background-color: ${props => `rgb(${props.pancolors.dots})`};
                    color: #FFFFFF;
                }
                .close{
                    cursor: pointer;
                    :hover{
                        color: ${props => `rgb(${props.pancolors.red})`};
                    }
                }
                :first-child {
                    margin-left: 0;
                }
                p{
                    margin: 0 0 0 5px;
                }
            }
        }
    }
`

const UserPos = (props) => {

    const { userPosError,
            userPos, 
            setUserPos,
            role = null } = props;

    const { panColors,
            clientData } = useContext(ConfigurationContext);
    
    const { basicData,
            posList,
            getPosToAsign} = useContext(ContentContext);

    const formStyle = {background_color: `rgba(${panColors.white}, 1)`,
                       border_color: `rgba(${panColors.middle_gray}, 1)`};

    useEffect(() => {
        
        if(posList === null){
            getPosToAsign();
        }
    
      return () => {}
    }, [posList])

    const handleRemoveLabel = (_label) => {
        
        let remove_label = [...userPos];
        const filtered = remove_label.filter(label => label !== _label);
        setUserPos(filtered);

    }

    const handleFormElementChange = (name, value) => {

        switch (name) {
            case 'product_labels':
                let result = userPos.filter(e => e === value);
                if(!result.length){
                    let update = [...userPos];
                    update.push(value);
                    setUserPos(update);
                }
                break;
            default:
                break;
        }
        
        return null;
        
    }

    return (
        <Wrapper 
            pancolors={panColors}
        >
            {posList ?
            <div className='box_labels'>
                <SelectCustom 
                    name={"product_labels"}
                    label={basicData.settings.users.section_fields.pos.label}
                    placeholder={basicData.settings.users.section_fields.pos.placeholder}
                    onChange={handleFormElementChange}
                    className={`field field_product_labels${userPosError ? " error" : ""}`}
                    type="text"
                    value={""}
                    help={""}
                    isRequired={basicData.settings.users.section_fields.pos.required}
                    disabled={clientData.name_clients !== "one_sp" ? false : (role === "6494b12090c83a00089bde56" || role === "6494c5eaf8cf9900084250dd" || role === "6494c715f8cf9900084251c5") ? false : userPos?.length ? true : false}
                    styles={ formStyle }
                    label_icon={<LocalizationAreaIcon />}
                    tooltip_content={""}
                    haveError={userPosError}
                    data={posList}
                />
                {userPos?.length ?
                    <div className='box_labels_selected'>
                        {userPos?.map((label, index) => {
                            let name = '';
                            name = getLabelName(posList, label);
                            
                            return(
                                <>
                                    {name ? 
                                        <div key={`label-${index}`} className={`label`}>
                                            <div className="close" onClick={() => handleRemoveLabel(label)}>x</div>
                                            <p>{ name }</p>
                                        </div>
                                    : ""}
                                </>
                            )
                        })}
                    </div> : ""
                }
            </div>
            :""}

        </Wrapper>

    )

}

export default UserPos;